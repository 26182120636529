<!--
markdown编辑组件

1. 通过@input进行数据的绑定
2. 自动的调整高度
-->
<template>
  <div class="markdown-editer">
    <textarea ref="textareaRef" @focus="handleFocus(true)" @blur="handleFocus(false)" :value="value" @input="handleChange"></textarea>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    value: {
      type: String,
      default: ''
    },
  },
  watch: {
    value() {
      this.$nextTick(() => {
        this.changeHeight()
      })
    }
  },
  data() {
    return {
      editHeight: 0,
    }
  },
  mounted () {
    this.changeHeight()
  },
  methods: {
    changeHeight() {
      console.log("changeHeight")
      this.$nextTick(() => {
        const textarea = this.$refs.textareaRef
        const nowHeight = textarea.scrollHeight
        textarea.style.height = '80px'
        if (nowHeight < 80) {
          textarea.style.height = 80 + 'px'
        } else {
          textarea.style.height = nowHeight + 'px'
        }
        this.editHeight = textarea.style.height
      })
    },
    handleChange(event) {
      this.$emit('input', event.target.value)
    },
    handleFocus(isFocus) {
      this.$emit('focus', isFocus)
    }
  }
}
</script>

<style lang="scss" scoped>
.markdown-editer{
  height: 100%;
  position: relative;

  textarea{
    position: relative; // absolute会脱离标准流
    font-size: 14px;
    color: #333;
    font-family: "Microsoft YaHei",serif;
    word-break: break-word;
    width: calc(100% + 8px);
    height: 100%;
    top: -1px;
    left: -2px;

    border: none;
    //border-left: 2px solid var(--color-card-h1-text);
    border-radius: 2px;
    outline: none;
    resize: none;

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: transparent;
      //background-color: red;
    }
  }
}
</style>
