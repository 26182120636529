<template>
  <div class="main">
    <button @click="createNote()">添加笔记</button>
    <button @click="createNote('1月22号\n\n\n\n#每日总结')">添加每日总结</button>
    <button @click="createNote">添加网址</button>

    {{ noteList.length }}

    <waterfall-flow ref="waterfall">
<!--      <NoteCard :edit-only="true" :note="{}"></NoteCard>-->
      <template v-for="note in noteList" v-slot:[note.id]>
        <NoteCard :note="note" :key="note.id"></NoteCard>
      </template>
    </waterfall-flow>
  </div>
</template>

<script>
import WaterfallFlow from '@/components/Layout/WaterfallFlow'
import NoteCard from '@/components/NoteCard'
import { nanoid } from 'nanoid'
import { debounce, throttle } from '@/utils/utils'
export default {
  name: 'WaterFallMain',
  components: { NoteCard, WaterfallFlow },
  data() {
    return {
      noteList: [
        // { "id": "_ntu98RFU5kl8IhVIsVln", "content": "真是很好看的视频 来自：https://www.bilibili.com/video/BV1Lg411r74j?spm_id_from=333.1007.tianma.1-1-1.click&vd_source=68a9f316a94ae92d0df40de2afcb1258", "tags": { "网址": { "background": "#56b9ba", "color": "#fff", "smart": true }, "哔哩哔哩": { "background": "#fb7299", "color": "#ffffff", "smart": true } }, "background": "#ffffff", "color": "#333333", "top": 333, "type": "", "height": 226, "state": 0, "secret": false, "createAt": 1661702270776, "updateAt": 1661702270776 },
        // { "id": "_Ma-XTO2Q0FlJBKIYZoVG", "content": "啦啦啦2 #标签\n\n#测试 #论文 #哔哩哔哩", "tags": {}, "background": "#ffffff", "color": "#333333", "top": 0, "type": "", "height": 100, "state": 1, "secret": false, "createAt": 1661698244694, "updateAt": 1661698244694 },
        // { "content": "### 可见一斑\n\n比喻见到事物的一小部分也能推知事物的整体。与“以小见大”相似。\n\n> 引用", "background": "#ffffff", "color": "#333333", "top": 668, "tags": {}, "state": 0, "type": "", "height": 213, "id": "pj7guKERjjkvdUIbfmI2A", "createAt": 1657700699005, "updateAt": 1657702740902 },
        // { "content": `卷积 (Convolution) 是一种数学运算，它用于在两个函数之间进行运算。在卷积神经网络中，卷积操作通常用于对图像进行处理。\n\n卷积操作的基本思想是，使用一个小的矩阵，称为卷积核（或滤波器），在图像上滑动并与图像的每个部分进行运算。这个运算的结果就是新的图像，称为特征图。\n\n每个卷积核都会学习一个特定的特征，如线条、边缘或斑点等。通过使用多个卷积核，卷积神经网络可以学习出图像的多层次特征。\n\n简单来说，卷积是一种对图像进行特征提取的方法。`, "background": "#ffffff", "color": "#333333", "top": 668, "tags": {}, "state": 0, "type": "", "height": 213, "id": "pj7guKERjjkvdUIbfmI2A-1", "createAt": 1657700699005, "updateAt": 1657702740902 },
        //
        // { "id": "_ntu98RFU5kl8IhVIsVln-1", "content": "真是很好看的视频 来自：https://www.bilibili.com/video/BV1Lg411r74j?spm_id_from=333.1007.tianma.1-1-1.click&vd_source=68a9f316a94ae92d0df40de2afcb1258", "tags": { "网址": { "background": "#56b9ba", "color": "#fff", "smart": true }, "哔哩哔哩": { "background": "#fb7299", "color": "#ffffff", "smart": true } }, "background": "#ffffff", "color": "#333333", "top": 333, "type": "", "height": 226, "state": 0, "secret": false, "createAt": 1661702270776, "updateAt": 1661702270776 },
        // { "id": "_Ma-XTO2Q0FlJBKIYZoVG-1", "content": "啦啦啦2", "tags": {}, "background": "#ffffff", "color": "#333333", "top": 0, "type": "", "height": 100, "state": 1, "secret": false, "createAt": 1661698244694, "updateAt": 1661698244694 },
        // { "content": "### 可见一斑\n\n比喻见到事物的一小部分也能推知事物的整体。与“以小见大”相似。\n\n> 引用", "background": "#ffffff", "color": "#333333", "top": 668, "tags": {}, "state": 0, "type": "", "height": 213, "id": "pj7guKERjjkvdUIbfmI2A-5", "createAt": 1657700699005, "updateAt": 1657702740902 },
        // { "content": `卷积 (Convolution) 是一种数学运算，它用于在两个函数之间进行运算。在卷积神经网络中，卷积操作通常用于对图像进行处理。\n\n卷积操作的基本思想是，使用一个小的矩阵，称为卷积核（或滤波器），在图像上滑动并与图像的每个部分进行运算。这个运算的结果就是新的图像，称为特征图。\n\n每个卷积核都会学习一个特定的特征，如线条、边缘或斑点等。通过使用多个卷积核，卷积神经网络可以学习出图像的多层次特征。\n\n简单来说，卷积是一种对图像进行特征提取的方法。`, "background": "#ffffff", "color": "#333333", "top": 668, "tags": {}, "state": 0, "type": "", "height": 213, "id": "pj7guKERjjkvdUIbfmI2A-2", "createAt": 1657700699005, "updateAt": 1657702740902 },
        //
        // { "id": "_ntu98RFU5kl8IhVIsVln-2", "content": "真是很好看的视频 来自：https://www.bilibili.com/video/BV1Lg411r74j?spm_id_from=333.1007.tianma.1-1-1.click&vd_source=68a9f316a94ae92d0df40de2afcb1258", "tags": { "网址": { "background": "#56b9ba", "color": "#fff", "smart": true }, "哔哩哔哩": { "background": "#fb7299", "color": "#ffffff", "smart": true } }, "background": "#ffffff", "color": "#333333", "top": 333, "type": "", "height": 226, "state": 0, "secret": false, "createAt": 1661702270776, "updateAt": 1661702270776 },
        // { "id": "_Ma-XTO2Q0FlJBKIYZoVG-2", "content": "啦啦啦2", "tags": {}, "background": "#ffffff", "color": "#333333", "top": 0, "type": "", "height": 100, "state": 1, "secret": false, "createAt": 1661698244694, "updateAt": 1661698244694 },
        // { "content": "### 可见一斑\n\n比喻见到事物的一小部分也能推知事物的整体。与“以小见大”相似。\n\n> 引用", "background": "#ffffff", "color": "#333333", "top": 668, "tags": {}, "state": 0, "type": "", "height": 213, "id": "pj7guKERjjkvdUIbfmI2A-4", "createAt": 1657700699005, "updateAt": 1657702740902 },
        // { "content": `卷积 (Convolution) 是一种数学运算，它用于在两个函数之间进行运算。在卷积神经网络中，卷积操作通常用于对图像进行处理。\n\n卷积操作的基本思想是，使用一个小的矩阵，称为卷积核（或滤波器），在图像上滑动并与图像的每个部分进行运算。这个运算的结果就是新的图像，称为特征图。\n\n每个卷积核都会学习一个特定的特征，如线条、边缘或斑点等。通过使用多个卷积核，卷积神经网络可以学习出图像的多层次特征。\n\n简单来说，卷积是一种对图像进行特征提取的方法。`, "background": "#ffffff", "color": "#333333", "top": 668, "tags": {}, "state": 0, "type": "", "height": 213, "id": "pj7guKERjjkvdUIbfmI2A-3", "createAt": 1657700699005, "updateAt": 1657702740902 },
      ]
    }
  },
  mounted () {
    this.initNote()

    for(let i = 0; i < 10; i++) {
      // this.createNote("### 可见一斑\n\n比喻见到事物的一小部分也能推知事物的整体。与“以小见大”相似。\n\n> 引用 卷积 (Convolution) 是一种数学运算，它用于在两个函数之间进行运算。在卷积神经网络中，卷积操作通常用于对图像进行处理。\n\n卷积操作的基本思想是，使用一个小的矩阵")
    }
  },
  methods: {
    // 产生20到500的随机数
    random (min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    /**
     * 初始化笔记列表
     */
    initNote() {
      this.$store.state.contentArr = JSON.parse(localStorage.getItem('ideaNotes') || '[]')
      this.noteList = this.$store.state.contentArr.slice(0, 60)
      this.$nextTick(() => {
        this.$refs.waterfall.init()
      })
    },
    /**
     * 创建空白笔记
     * @param content
     */
    createNote(content = "") {
      const id = nanoid() // => "V1StGXR8_Z5jdHi6B-myT"
      const createAt = new Date().getTime()
      const note = {
        content,
        background: '#ffffff', // 背景颜色
        color: '#333333', // 字体颜色
        tags: {}, // { '网址': { background: '#56b9ba', color: '#fff', smart: false } }, // 智能标签，智能标签每次更新文本的时候会自动删除，然后根据文本内容来自动生成标签
        state: 0, // 0-正常 1-删除 2-归档
        secret: false, // 是否隐藏
        type: '', // 笔记类型
        id,
        tmp: true, // 临时字段，说明现在刚刚新建卡片，数据并没有保存，当卡片内容发生变化以后，移除这个字段
        createAt: createAt,
        updateAt: createAt
      }
      // 向前添加元素
      this.noteList.unshift({ ...note, edit: true })
      this.$nextTick(() => {
        // this.$refs.waterfall.init()
        // throttle(() => {
        this.$refs.waterfall.init()
        // }, 1000)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.main {
  background-color: #f4f4f4;
  height: 100%;
}
</style>
