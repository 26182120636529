<template>
  <div class="container" @click="mainClick">
    <Header @addNote="createNote" @search="search" @foldMenu="foldMenu" ref="headerRef"></Header>

    <div class="content">
      <Menu v-show="menuShow"
            :filterRules="filterRules"
            :active="filterRules.status[0]"
            @changeFilterRulesState="changeFilterRulesState"
            @clearFilterRulesTags="clearFilterRulesTags"
            @clearFilterRulesIncludeTags="clearFilterRulesIncludeTags"></Menu>
      <div id="main" class="main">
        <Login v-show="!$store.state.user.username"></Login>
        <waterfall-flow v-show="$store.state.user.username" ref="waterfall">
          <template v-for="note in noteList" v-slot:[note.id]>
            <NoteCard :note="note" :key="note.id" @save="saveNote"></NoteCard>
          </template>
        </waterfall-flow>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/utils/utils'
import Header from '@/components/Layout/Header'
import Menu from '@/components/Layout/Menu'
import WaterfallFlow from '@/components/Layout/WaterfallFlow'
import NoteCard from '@/components/NoteCard'
import Login from '@/components/Login'
import { nanoid } from 'nanoid'
import { getInfo } from '@/api/user'
import dayjs from 'dayjs'
import * as auth from '@/utils/auth'
import * as noteApi from '@/api/note'

export default {
  name: 'Main',
  components: {
    WaterfallFlow,
    Header,
    Menu,
    NoteCard,
    Login
  },
  watch: {
    "$store.state.user.username": function() {
      this.refresh()
    }
  },
  data() {
    return {
      menuShow: true,
      noteList: [],
      createNoteTmpList: [],
      filterRules: {
        status: [0],
        secret: false, // 是否显示私人笔记，true 表示显示笔记secret为true的笔记
        includeText: '',
        tags: [], // ['网址'] 且的关系，显示只有这些标签的文章
        includeTags: [], // 包含关系，显示所有有这个标签内容的笔记
        shuffle: false, // 是否打乱顺序显示
      },
    }
  },
  created () {
    this.loadNotes()
  },
  mounted () {
    this.refresh()
  },
  methods: {
    isLogin() {
      const user = auth.getUser()
      this.$store.state.user.username = user.username

      if (this.$store.state.user.username === '') {
        return false
      }
      return true
    },
    saveNote(note) {
      noteApi.addNote(note).then(res => {
        console.log(res)
      })
    },
    getNoteListTmp() {
      return this.noteList.filter(note => note.tmp)
    },
    /**
     * 1. 过滤笔记的状态
     * 2. 把临时创建的笔记放到最前面
     * 3. 只显示前60条笔记
     */
    filterNote() {
      const tagSet = new Set()
      this.allTags = {}
      // 1. 过滤笔记的状态 正常 删除 还是归档
      this.noteList = this.$store.state.contentArr.filter(item => {
        if (this.filterRules.status.includes(item.state)) {
          return true
        }
        return false
      })

      // 2. 把临时创建的笔记放到最前面
      if(this.filterRules.status[0] === 0) {
        this.noteList.unshift(...this.createNoteTmpList)
      }

      // 3. 只显示前60条笔记
      this.noteList = this.noteList.slice(0, 60)

    },

    /**
     * 收缩展开菜单
     * @function foldMenu
     */
    foldMenu() {
      this.menuShow = !this.menuShow
      this.$nextTick(() => {
        this.refresh()
      })
    },
    /**
     * 载入所有笔记
     */
    loadNotes() {
      this.getNoteList()
    },
    async getNoteList() {
      if(!this.isLogin()) return

      const res = await noteApi.getNoteList()
      this.noteList = res.data
    },
    autoCreateNote() {
      if(this.filterRules.status[0] === 0) {
        this.createNote('\n\n\n\n', false)
      }
    },
    async refresh() {
      console.log("进行整个笔记的刷新")
      this.createNoteTmpList = this.getNoteListTmp() // 获取目前的临时笔记
      console.log("临时笔记", this.createNoteTmpList, this.filterRules.status[0])
      await this.getNoteList()
      // 2. 把临时创建的笔记放到最前面
      if(this.filterRules.status[0] === 0) {
        this.noteList.unshift(...this.createNoteTmpList)
      }
      console.log("需要显示的笔记", this.noteList)
      // this.filterNote() // todo: 笔记过滤，因为进行了笔记的过滤，导致了无法批量添加很多卡片
      // this.autoCreateNote()
      this.$nextTick(() => {
        this.$refs.waterfall.init()
      })
    },
    // 改变过滤规则中的state
    changeFilterRulesState(state) {
      console.log("changeFilterRulesState", state)
      this.filterRules.status = state
      this.refresh()
    },
    /**
     * 清空筛选规则中的【只包含】标签
     */
    clearFilterRulesTags() {
      if(this.filterRules.tags) { // 防止不必的刷新
        this.filterRules.tags = []
        this.refresh()
      }
    },
    /**
     * 清空筛选规则中的【包含】标签
     */
    clearFilterRulesIncludeTags() {
      if(this.filterRules.includeTags) { // 防止不必的刷新
        this.filterRules.includeTags = []
        this.refresh()
      }
    },
    /**
     * 点击空白处，清空搜索内容和筛选结果
     * @param event
     */
    mainClick(event) {
      if(['menu', 'column', 'main', 'container', 'waterfall-flow'].includes(event.target.className)) {
        console.log("点击了空白处")
        this.refresh()
      }
    },

    /**
     * 创建空白笔记
     * @param content
     */
    createNote(content = "\n\n\n\n", refresh = true) {
      const id = nanoid() // => "V1StGXR8_Z5jdHi6B-myT"
      // const createAt = new Date().getTime()

      // YYYY-MM-DD HH:MM
      const createAt = dayjs().format('YYYY-MM-DD HH:mm:ss')
      const note = {
        content,
        background: '#ffffff', // 背景颜色
        color: '#333333', // 字体颜色
        tags: {}, // { '网址': { background: '#56b9ba', color: '#fff', smart: false } }, // 智能标签，智能标签每次更新文本的时候会自动删除，然后根据文本内容来自动生成标签
        state: 0, // 0-正常 1-删除 2-归档
        secret: false, // 是否隐藏
        type: '', // 笔记类型
        id,
        tmp: true, // 临时字段，说明现在刚刚新建卡片，数据并没有保存，当卡片内容发生变化以后，移除这个字段
        createAt: createAt,
        updateAt: createAt
      }
      // 向前添加元素
      this.noteList.unshift({ ...note, edit: true })
      this.$nextTick(() => {
        if (refresh) this.refresh()
      })
    },
    search(content) {
      console.log("搜索内容", content)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;

  .content {
    display: flex;

    #main {
      padding: 20px 0;
      margin: 0 auto;
      margin-top: 50px;
      display: flex;
      width: 100%;
      height: calc(100vh - 50px);
      overflow-x: hidden;
      overflow-y: scroll;
      justify-content: center; // center
    }
  }
}
</style>
