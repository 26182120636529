<!--
动态插槽练习，父组件向子组件传递一个slots组件列表，然后子组件直接进行展示

例如瀑布流组件，通过这种方式，可以将瀑布流中的样式js和css完全隔离，使用的时候只需要关注数组即可

-->
<template>
<div>

  <template v-for="(_, slotName) in $slots">
    <slot :name="slotName"></slot>
  </template>

</div>
</template>

<script>
export default {
  name: 'SlotTest',
  mounted () {
    console.log(this.$slots)
  }
}
</script>

<style scoped>

</style>
