import { debounce, decrypt, encrypt } from '@/utils/utils'
import noteLogsApi, { getNoteLogs } from '@/api/noteLogs'
import { nanoid } from 'nanoid'
import Toastify from 'toastify-js'
/**
 * 日志文件的混入
 * @module mixin/logMixin
 */
export default {
  methods: {
    /**
     * 任何对笔记内容的操作都会进行日志的添加，具体的添加步骤
     * 1. 读取vuex中的noteLogs变量内容，如果没有则读取本地的内容
     * 2. 创建一个空白的日志信息json，包含了日志的id，创建时间createAt，这一次的操作option 目前包含(EditNote AddNote)，和具体的data数据
     * 3. 讲日志信息push道noteLogs变量中
     * 4. 异步进行日志的存储，防止存储日志时的卡顿，具体的日志存储操作（将noteLogs内容存储道本地，调用updateAllLogs()方法将数据同步到服务器）
     *
     * @summary 添加日志
     * @param option EditNote-编辑笔记 AddNote-添加笔记
     * @param note 笔记内容
     */
    addLog({ option, note }) {
      console.time("addLog添加日志用时")

      // console.log('记录日志', option, note)
      if(!this.$store.state.noteLogs) {
        // 如果日志为空
        // 获取localStorage中的日志，然后添加日志，这里需要优化，只有打开和关闭的时候才访问localStorage
        if(localStorage.getItem('NoteLogs')) {
          this.$store.state.noteLogs = JSON.parse(decrypt(localStorage.getItem('NoteLogs'))) || []
        }
      }

      const id = nanoid() // => "V1StGXR8_Z5jdHi6B-myT"
      const log = {
        id,
        createAt: new Date().getTime(),
        option: option, // EditNote AddNote
        data: {
          note: note
        }
      }
      this.$store.state.noteLogs.push(log)
      this.$store.state.logNum++ // 日志数量增加
      // console.log("happen->NoteLogs", NoteLogs)
      // this.$store.state.noteLogs = NoteLogs
      // 一秒以后，上传所有日志到服务器
      debounce(() => {
        if(!this.$store.state.updateInterval) {
          // 如果没有上传日志的线程，则开一个进行上传
          this.$store.state.updateInterval = setInterval(() => {
            this.updateAllLogs()
          }, 100) // 每隔100毫秒进行一次日志的上传
        }
        localStorage.setItem('NoteLogs', encrypt(JSON.stringify(this.$store.state.noteLogs)))
        console.log("日志保存本地")
      }, 1000)
      // localStorage.setItem('NoteLogs', JSON.stringify(NoteLogs))

      console.timeEnd("addLog添加日志用时")
    },
    /**
     * 遍历本地所有日志，如果没有上传，则进行上传的操作
     * this.$store.state.updateLogsIdList 保存了所有上传过的日志id，防止重复上传，当然，重复的上传也不会造成数据的损坏，只是会造成性能的浪费
     * @summary 上传日志到服务器
     */
    async updateAllLogs() {
      // 查看是否开启日志同步的设置，如果没有，则直接忽略这个函数
      if(this.$store.state.setting.updateLogsOpen === false) {
        if(this.$store.state.updateInterval) {
          clearInterval(this.$store.state.updateInterval)
          this.$store.state.updateInterval = null
        }
        console.log("未开启数据同步")
        return
      }
      // 线程锁
      if (!this.$store.state.updateFlag) {
        // 将同步标记置为true
        this.$store.state.updateFlag = true
        // 获取所有的日志列表
        let noteLogs = this.$store.state.noteLogs
        console.log('noteLogs.length', noteLogs.length)
        // 获取已经同步的日志id
        let updateLogsIdList = this.$store.state.updateLogsIdList
        updateLogsIdList = new Set(updateLogsIdList)
        // 寻找需要同步的日志下标，进行上传日志下标的增加
        // this.$store.state.updateLogsIndex 系统打开的时候从0开始，使用的过程中没必要每次都从0开始
        // 1. 日志的下标不能大于日志的数量
        // 2. 当前下标对应日志的id是否上传过 上传过 + 1，没上传过直接进行上传
        // todo TypeError: Cannot read properties of undefined (reading 'id')
        // log Num = 10 index最大是9
        // log index = 9的话，
        while(this.$store.state.updateLogsIndex < noteLogs.length && updateLogsIdList.has(noteLogs[this.$store.state.updateLogsIndex].id) === true) {
          this.$store.state.updateLogsIndex = this.$store.state.updateLogsIndex + 1
          noteLogs = this.$store.state.noteLogs // 在添加日志的时候会修改，因此不断的更新一下
        }
        // 3. 这个下标就是最后了 i == len - 1 直接结束
        if(this.$store.state.updateLogsIndex >= noteLogs.length) {
          console.log("同步结束")
          if(this.$store.state.updateInterval) {
            clearInterval(this.$store.state.updateInterval)
            console.log("clearInterval", this.$store.state.updateInterval)
            this.$store.state.updateInterval = null
          }
          this.$store.state.updateLogState = 1
          this.$store.state.updateFlag = false
          // todo 同步完成，这里可以保存一下setting，但是setting函数目前没办法调用
          return
        }

        // 准备同步的日志
        const nowNoteLog = noteLogs[this.$store.state.updateLogsIndex]
        // 如果这个日志还没有同步
        if(updateLogsIdList.has(nowNoteLog.id) === false) {
          this.$store.state.updateLogState = 0
          // 进行日志同步
          await noteLogsApi.add(nowNoteLog).then((data) => {
            if(data.code === 200) {
              // 同步成功，将本条日志记录到上传列表中
              updateLogsIdList.add(noteLogs[this.$store.state.updateLogsIndex].id) // todo 这里目前有问题，执行到这里的时候会抛异常 TypeError: Cannot read properties of undefined (reading 'id')
              console.log("同步成功，将本条日志记录到上传列表中")
            } else if(data.code === 401) { // 密码错误
              this.$store.state.updateLogState = 3
              if(this.$store.state.updateInterval) {
                clearInterval(this.$store.state.updateInterval)
                this.$store.state.updateInterval = null
              }
              return
            } else {
              // 同步出现了问题
              console.log("同步出现了问题", data.msg)
              this.$store.state.updateLogState = 2
              if(this.$store.state.updateInterval) {
                clearInterval(this.$store.state.updateInterval)
                this.$store.state.updateInterval = null
              }
              return
            }
            this.$store.state.updateLogsIdList = Array.from(updateLogsIdList)
          })
        }
        this.$store.state.updateFlag = false
      }
    },
    /**
     * 拉取服务器上的日志，并进行合并操作
     * @returns {Promise<void>}
     */
    async pullNoteLog() {
      if(this.$store.state.setting.updateLogsOpen === false) {
        if(this.$store.state.pullInterval) {
          clearInterval(this.$store.state.pullInterval)
          this.$store.state.pullInterval = null
        }
        console.log("未开启数据同步")
        return
      }

      this.$store.state.pullNoteLogFlag = true
      // 网络上的日志
      const NoteLogs = await getNoteLogs()
      console.time('拉取日志')
      // 当前日志
      let oldNoteLogs = []
      if(localStorage.getItem('NoteLogs')) { // todo 这里需要优化，没必要每次操作localStorage
        oldNoteLogs = decrypt(localStorage.getItem('NoteLogs'))
        oldNoteLogs = JSON.parse(oldNoteLogs)
      }
      const oldLength = oldNoteLogs.length
      const IdList = oldNoteLogs.map(item => item.id)
      // const createAtList = oldNoteLogs.map(item => item.createAt)
      // console.log(createAtList)
      let num = 0
      for(const item of NoteLogs) { // item 是日志
        if(!IdList.includes(item.id)) {
          oldNoteLogs.push(item)
          this.$store.state.updateLogsIdList.push(item.id) // 这条日志是从服务器拉下来的，就没必要再上传了
          this.$store.state.contentArr.unshift(item.data.note)
          this.$store.state.setting.updateCreateAt = item.createAt // 更新为最新的时间，防止重复的拉取服务器的日志
          num++
        }
      }

      this.$store.state.logNum = oldNoteLogs.length

      oldNoteLogs = oldNoteLogs.sort((a, b) => a.createAt - b.createAt)
      localStorage.setItem("NoteLogs", encrypt(JSON.stringify(oldNoteLogs)))
      // this.$nextTick(() => {
      //   this.$parent.init()
      // })
      console.timeEnd('拉取日志')
      this.saveSetting()
      const toastElement = Toastify({
        text: `更新了${num}条日志`,
        gravity: 'bottom',
        position: 'right',
        duration: 10000,
        close: false,
        backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
      })
      if(num) {
        toastElement.showToast()
      }
      this.$store.state.pullNoteLogFlag = false
    },
  }
}
