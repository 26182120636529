<!--
笔记卡片
实现的功能
1. 笔记内容渲染
2. 笔记内容编辑
3. 双击卡片进行状态切换
-->
<template>
  <Card @dblclick.prevent.stop="markdownDblClick" :class="{focus: editIsFocus, edit: state === 'edit' && editIsFocus !== true}" ref="cardRef" :id="`note_${note.id}`" class="note-card" :key="`card_note_${note.id}`">
    <MarkdownRender ref="render" v-if="state === 'show'" v-model="note.content" :key="`card_markdown_note_${note.id}`"></MarkdownRender>
    <MarkdownEditer v-else @focus="editerFocus" v-model="note.content"></MarkdownEditer>
    <template v-slot:footer>
      <div class="edit-save">
        <button v-if="state === 'edit'" @click="toShow" class="finish">完成</button>
        <button v-else @click="toEdit">编辑</button>
      </div>
      <span class="test-info">卡片id: note_{{note.id}} {{note.tmp ? '临时创建' : ''}} createAt:{{note.createAt}}</span>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Card'
import MarkdownRender from '@/components/MarkdownRender'
import MarkdownEditer from '@/components/MarkdownEditer'
export default {
  name: 'NoteCard',
  components: { MarkdownRender, MarkdownEditer, Card },
  data() {
    return {
      state: 'show',
      editIsFocus: false,
    }
  },
  props: {
    note: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {

  },
  mounted () {
    console.log("创建卡片")
    if(this.note.edit) {
      this.toEdit()
    }
  },
  methods: {
    toShow() {
      this.state = 'show'
      this.$emit('save', this.note)
      if(this.note.tmp) {
        delete this.note.tmp
      }
      if(this.note.edit) {
        delete this.note.edit
      }
    },
    toEdit() {
      this.state = 'edit'
    },
    editerFocus(isFocus) {
      this.editIsFocus = isFocus
    },
    markdownDblClick(event) {
      if(this.state === 'show') {
        this.toEdit()
      } else {
        this.toShow()
      }
      this.$emit('dblclick', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.note-card {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin: 0;

  &.focus {
    box-shadow: 0 0 6px rgba(85, 187, 136, 0.96) !important;
  }
  &.edit {
    box-shadow: 0 0 6px rgba(106, 192, 243, 0.96) !important;
  }

  .test-info {
    color: #d2d1d1;
    font-size: 12px;
  }
  .edit-save {
    .finish {
      background-color: #5B8F;
      color: white;
      &:hover {
        background-color: #4ca47d; // #f5f5f5
      }
    }
  }

  button {
    cursor: pointer;
    height: 20px;
    //width: 60px;
    padding: 0 10px;
    border: none;
    outline: none;
    border-radius: 10px;
    background-color: #f5f5f5;
    &:hover {
      background-color: #dcdcdc; // #f5f5f5
    }
  }
}
</style>
