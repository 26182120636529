import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '@/views/IndexView'
import TestMain from '@/views/Test/Main'
import WaterfallFlow from '@/components/Layout/WaterfallFlow'
import TestWaterfallFlow from '@/views/Test/WaterfallMain'
import Main from '@/views/Index/Main'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'IndexView',
    component: IndexView
  },
  {
    path: '/v2',
    name: 'Main',
    component: Main
  },
  {
    path: '/test',
    name: 'Test',
    component: TestMain
  },
  {
    path: '/waterfall',
    name: 'Waterfall',
    component: WaterfallFlow
  },
  {
    path: '/testWaterfall',
    name: 'TestWaterfallFlow',
    component: TestWaterfallFlow
  }
]

const router = new VueRouter({
  routes
})

export default router
