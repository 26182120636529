<!--
瀑布流组件

教程：https://www.cnblogs.com/zsxblog/articles/15153692.html#/

进行优化：

使用的时候，只需要传入一个插槽列表，然后对其进行渲染，不需要关心中间的瀑布流过程。

-->
<template>
  <div class="waterfall-flow" ref="waterfallRef">
    <div class="column" v-for="(column,index) in columns" :key="index">
      <div
        class="item"
        v-for="(item) in column.columnArr"
        :key="'column-item-' + item.slotName"
        :style="{width: columnWidth + 'rem'}"
      > <!-- :style="{ height: item.height + 'px'}"-->
        <slot :name="item.slotName"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from '@/utils/utils'

export default {
  name: 'WaterfallFlow',
  props: {
    columnWidth: {
      type: Number,
      default: () => 14
    }
  },
  data() {
    return {
      contentArr: [
        // { height: "150", top: "", slotName: '' },
        // { height: "250", top: "", slotName: '' },
      ],
      columns: [],
      arrIndex: [],
      loading: false,
      contentArr2: [],
      heightMap: {}
    }
  },
  methods: {
    getMinHeight(arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i].height) + parseInt(arr[i].top))
      }
      return Math.min.apply(null, a)
    },
    getMinIndex(val) {
      for (let i = 0; i < this.columns.length; i++) {
        const height = this.columns[i].columnArr[this.columns[i].columnArr.length - 1].height
        const top = this.columns[i].columnArr[this.columns[i].columnArr.length - 1].top
        if(parseInt(height) + parseInt(top) == val) {
          this.arrIndex.push(i)
        }
      }
    },
    /**
     * 改变笔记高度
     * todo: 这里可能没必要每次都计算高度，可以通过监听笔记的高度变化来触发，后续优化一下
     */
    changeHeight(note_id) {
      // 产生1000到10000的随机数
      const times = Math.floor(Math.random() * (30000 - 1000 + 1) + 1000)
      console.log("异步计算高度changeHeight")

      setTimeout(() => {
        const el = document.getElementById("note_" + note_id)
        if (el) {
          // console.log("高度", el.clientHeight)
          const nowHeight = el.clientHeight
          this.heightMap = localStorage.getItem('noteHeightMap') ? JSON.parse(localStorage.getItem('noteHeightMap')) : {}
          this.heightMap[note_id] = nowHeight
          // console.log("拿到的笔记卡片的高度", nowHeight)
          localStorage.setItem("noteHeightMap", JSON.stringify(this.heightMap))
        } else {
          console.warn("获取不到el")
        }
      }, times)
    },

    init() {
      console.log('瀑布流init')
      this.heightMap = localStorage.getItem('noteHeightMap') ? JSON.parse(localStorage.getItem('noteHeightMap')) : {}
      // 获取插槽内容，并进行组装
      this.contentArr = []
      for(const slotName in this.$slots) {
        // console.log("插槽名称", slotName, this.$slots[slotName])
        this.changeHeight(slotName)
        this.contentArr.push({ height: this.heightMap[slotName] ? this.heightMap[slotName] : 80, top: "", slotName: slotName })
      }

      this.columns = []
      const contentLen = this.contentArr.length
      // 根据可视区域的宽度判断需要几列
      // const cWidth = document.documentElement.clientWidth || document.body.clientWidth
      const mainEl = this.$refs.waterfallRef
      if(!mainEl) {
        console.log('没有找到main元素，直接接触init初始化')
        return
      }
      const cWidth = mainEl.clientWidth // 比如给个宽度100%才会获取到正确的宽度

      // 假设图片宽度为100px
      const cLen = Math.floor((cWidth / ((this.columnWidth) * 16 + 10))) // 计算有多少列
      console.log(cLen)
      console.log("this.contentArr.length < cLen 会返回", this.contentArr.length, cLen)
      if(cLen === 0) return

      // 初始化每一列的第一行元素
      for (let i = 0; i < cLen && i < this.contentArr.length; i++) {
        this.contentArr[i].top = 0 // 预设距离顶部值为0
        this.columns.push({ columnArr: [this.contentArr[i]] })
      }

      // 对剩余元素的判断，应该放到哪一列
      for (let index = cLen; index < contentLen; index++) {

        this.arrIndex = []
        const arr = [] // 找到高度最小的一列，可能有多个
        let minHeight = 0 // 高度最小的一列的高度
        let pushIndex = 0 // 高度最小的一列所在位置的索引

        for (let i = 0; i < this.columns.length; i++) {
          arr.push({
            height: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].height,
            top: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].top
          })
        }

        minHeight = this.getMinHeight(arr)
        this.getMinIndex(minHeight)
        if(this.arrIndex.length > 0) {
          pushIndex = Math.min.apply(null, this.arrIndex) // 出现高度一样的，去索引最小的
        }

        this.contentArr[index].top = minHeight + 20
        this.columns[pushIndex].columnArr.push(this.contentArr[index])

      }
    },
  },
  mounted() {
    this.init()
    window.addEventListener('resize', () => {
      console.time('resize-time')
      throttle(this.init, 300)
      console.timeEnd('resize-time')
    })
  },
}
</script>

<style lang="scss" scoped>
.waterfall-flow {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: 20px;
  display: flex;
  justify-content: center; // 让卡片紧凑一点
  /* background: pink; */
  transition: all 0.5s ease-in-out;

  .column {
    //margin-left: 5px;
    //margin-right: 5px;
    padding: 0 5px;
  }
}
.item {
  width: 14rem;
  //color: #000;
  //margin-top: 20px;
  display: flex;
  //justify-content: center;
  //align-items: center;
  transition: all 0.5s ease-in-out;

  //margin: 5px 5px;
  //margin-bottom: 10px;
  //margin-top: 10px;
  padding: 5px 0;
}

</style>
