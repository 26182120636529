<template>
  <div class="edit-container" ref="edit-container">
    <div class="edit-main" :class="{expend: expand}">
      <div class="header">
        <span class="status">{{editItem.id ? '编辑': '创建'}}</span>
        <span class="tools">
            <button @click="editInsertCode" title="插入代码"><i class="fa fa-code"></i></button>
            <button @click="editInsertHeader" title="插入标题"><i class="fa fa-header"></i></button>
          <button @click="editInsertMore" title="插入折叠内容">more</button>
          </span>
        <span v-if="editItem.id" class="timeBar">
            <span class="time">创建时间: {{ timeFormat(editItem.createAt) }}</span>
            <span class="time">更新时间: {{ timeFormat(editItem.updateAt) }}</span>
          </span>
        <span class="expand"><button @click="editExpand"><i class="fa" :class="!expand ? 'fa-expand': 'fa-compress'"></i></button></span>
        <span class="close"><button @click="editClose">完成</button></span> <!---->
      </div>
<!--    @keyup.shift.tab.prevent="editShiftTab" @keyup.tab.prevent="editTab"     -->
      <left-right-container>
        <template v-slot:left>
          <textarea ref="editRef" @keydown="keyEvent" v-if="editItem" @input="changeEditHeight" name="" id="edit-input" v-model="editItem.content"></textarea>
        </template>
        <template v-slot:right>
          <div class="text" v-html="markdown(editItem.content)" ref="markdownRef"></div>
        </template>
      </left-right-container>

      <vue-tags-input
        v-model="editTag.tag"
        :tags="editTag.tags"
        :autocomplete-items="filteredItems"
        placeholder="添加标签"
        @tags-changed="newTags => editTag.tags = newTags"
      />
      <!-- 卡片修改背景色和字体字体颜色感觉效果很不好，所以取消 -->
<!--      <div class="colorTools" v-if="false">-->
<!--        <input class="colorSelect bg" title="背景色" type="color" v-model="editItem.background"/>-->
<!--        <input class="colorSelect text" title="文字颜色" type="color" v-model="editItem.color"/>-->
<!--        <div class="colorList">-->
<!--          <span class="colorItem" :style="{ background: item.background, color: item.color }" v-for="(item, index) of colors" :key="index">{{item.text}}</span>-->
<!--        </div>-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>

import VueTagsInput from '@johmun/vue-tags-input'
import { timeFormat } from '@/utils/utils'
import LeftRightContainer from '@/components/LeftRightContainer'
import markedMixin from '@/mixin/markedMixin'

export default {
  name: 'EditDialog',
  mixins: [markedMixin],
  components: { LeftRightContainer, VueTagsInput },
  props: {
    editItem: {
      type: Object
    },
    editTag: {
      type: Object
    },
  },
  data() {
    return {
      expand: false
    }
  },
  computed: {
    filteredItems() {
      return this.editTag.autocomplete.filter(i => {
        return i.text.toLowerCase().indexOf(this.editTag.tag.toLowerCase()) !== -1
      })
    },
  },
  methods: {

    /**
     * 编辑框放大缩小
     */
    editExpand() {
      this.expand = !this.expand
    },
    keyEvent(event) {
      if(event.shiftKey) {
        if(event.key === 'Tab') {
          this.editShiftTab()
          event.preventDefault()
        }
      } else {
        if(event.key === 'Tab') {
          this.editTab()
          event.preventDefault()
        }
      }
    },
    editTab() {
      const textarea = this.$refs.editRef
      const indent = ' '
      const start = textarea.selectionStart
      const end = textarea.selectionEnd
      let selected = window.getSelection().toString()
      console.log(selected)
      selected = indent + selected.replace(/\n/g, '\n' + indent)
      textarea.value = textarea.value.substring(0, start) + selected +
        textarea.value.substring(end)
      textarea.setSelectionRange(start, start +
        selected.length)
      this.editItem.content = textarea.value // 这样写就可以同步数据了
    },
    editShiftTab() {
      const textarea = this.$refs.editRef
      const start = textarea.selectionStart
      const end = textarea.selectionEnd
      let selected = window.getSelection().toString()
      selected = selected.replace(/^\s/gm, '')
      textarea.value = textarea.value.substring(0, start) + selected +
        textarea.value.substring(end)
      textarea.setSelectionRange(start, start +
        selected.length)
      this.editItem.content = textarea.value // 这样写就可以同步数据了
    },
    editInsertMore() {
      const textarea = this.$refs.editRef
      const indent = '\n\n<!--more-->\n\n'
      const start = textarea.selectionStart
      const end = textarea.selectionEnd
      textarea.value = textarea.value.substring(0, start) + indent + textarea.value.substring(end)
      this.editItem.content = textarea.value // 这样写就可以同步数据了
    },
    editInsertCode() {
      const textarea = this.$refs.editRef
      const indent = '\n```\n\n```\n'
      const start = textarea.selectionStart
      const end = textarea.selectionEnd
      textarea.value = textarea.value.substring(0, start) + indent + textarea.value.substring(end)
      this.editItem.content = textarea.value // 这样写就可以同步数据了
      console.log("start", start)
    },
    editInsertHeader() {
      const textarea = this.$refs.editRef
      const indent = '### '
      const start = textarea.selectionStart
      const end = textarea.selectionEnd
      textarea.value = textarea.value.substring(0, start) + indent + textarea.value.substring(end)
      this.editItem.content = textarea.value // 这样写就可以同步数据了
      // 设置光标位置
      // this.$nextTick(() => {
      //   textarea.selectionStart = (textarea.value.substring(0, start) + indent).length
      //   textarea.selectionEnd = (textarea.value.substring(0, start) + indent).length
      //   console.log("光标位置",textarea.selectionStart,textarea.selectionEnd  )
      // })
    },
    timeFormat(time) {
      return timeFormat(time)
    },
    /**
     * 刷新输入框的高度
     */
    changeEditHeight() {
      // 获取屏幕高度
      const cHeight = document.documentElement.clientHeight || document.body.clientHeight
      const textarea = this.$refs.editRef
      const markdown = this.$refs.markdownRef // 渲染的markdown也要进行高度显示

      textarea.style.height = '200px'
      const nowHeight = textarea.scrollHeight
      console.log("textarea.scrollHeight", textarea.scrollHeight)
      if(nowHeight <= cHeight - 200) {
        textarea.style.height = nowHeight + 'px'
        markdown.style.height = nowHeight + 'px'
        // textarea.style.overflowY = 'hidden'
      } else if(nowHeight > cHeight - 200) {
        textarea.style.height = (cHeight - 200) + 'px'
        markdown.style.height = (cHeight - 200) + 'px'
      }
      // textarea.style.scrollTop = 0 // 防抖动

      // console.log(textarea.scrollHeight)
    },
    editClose() {
      this.$emit('editClose')
    }
  },
  created () {
    // 判断屏幕宽度，如果太窄了，自动把菜单收起来
    const clientW = document.documentElement.clientWidth || document.body.clientWidth
    if(clientW < 700) {
      this.expand = true
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.editRef.focus()
    })

    // 绑定编辑框背景点击事件，目前只能这样，因为会事件冒泡，还解决不了
    this.$refs['edit-container'].addEventListener('mousedown', (event) => {
      if (event.target == this.$refs['edit-container']) { // 判断是否是当前绑定事件的元素元素触发的该事件
        // this.editClose()
        this.$emit('editClose')
        event.cancelBubble = true
      }
    })


  }
}
</script>

<style lang="scss" scoped>
.edit-container {
  position: fixed; // 全屏显示编辑框
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(58, 56, 56, 0.72);
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  .edit-main {
    transition: all 0.1s linear;
    &.expend {
      width: 100%;
      height: 100%;
      border-radius: 0;
      textarea {
        //height: 100%;
        flex: 1;
      }
      .vue-tags-input {
        //height: 70px;
        margin-bottom: 30px;
      }
    }

    width: 500px;
    border-radius: 10px;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.29);

    //height: 200px;
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    .header {
      height: 30px;
      position: absolute;
      left: 0;
      border-bottom: 1.5px solid rgba(178, 178, 178, 0.7);
      width: calc(100%);
      display: flex;
      user-select: none;
      color: #888;
      .status {
        font-weight: bold;
        //font-family: "黑体";
        margin-left: 10px;

        transform: translateY(-2px);
      }
      .tools {
        margin-left: 10px;
        button {
          background-color: transparent;
          //border: 1px red solid;
          border: 0;
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 5px;
          transform: translateY(-4px);
          &:hover {
            background-color: #dcdcdc;
          }
        }
      }
      .timeBar {
        //display: inline-block;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: translateY(-4px);
        //flex: 1;
        opacity: 0;
        &:hover {
          opacity: 1;
        }

        .time {
          margin-left: 10px;
          font-size: 9px;
          color: #cecece;
        }
      }
      .expand {
        margin-left: auto;
      }
      .expand, .close {

        button {
          border: 0;
          background-color: transparent;
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 5px;
          transform: translateY(-4px);

          &:hover {
            background-color: #dcdcdc;
          }
          &:active {
            //transform: scale(0.98);
          }
        }
        margin-right: 10px;
      }

    }
    .left-right-container {
      margin-top: 35px; // 偏移出来头部的距离
      .text {
        //font-size: 14px;
        color: #333;
        font-family: "Microsoft YaHei";
        word-break: break-word;
        //overflow: hidden;
        overflow: auto;

        // 带有chekbox的li
        :deep .note-checkbox {
          list-style: none;
          transform: translateX(-15px);
          //padding-left: 4px;
          //display: flex;
          //align-items: center;

          input[type=checkbox] {
            //transform: translateX(-19px);
            transform: translateY(1.5px);
            -webkit-appearance: none;
            //-moz-appearance: none;
            position: relative;
            right: 0;
            bottom: 0;
            left: 0;
            height: 1.25rem;
            width: 1.25rem;
            transition: 0.2s;
            color: #fff;
            cursor: pointer;
            margin: 0.4rem 0 0.4rem;
            margin-right: 0.3rem;
            outline: 0;
            border-radius: 0.1875rem;
            vertical-align: -0.65rem;
            border: 2px solid #2f4154;

            &:after, &:before {
              content: " ";
              transition: 0.2s;
              position: absolute;
              background: #fff;
            }
            &:after {
              right: 0.5625rem;
              bottom: 0.1875rem;
              width: 0.125rem;
              height: 0;
              -webkit-transform: rotate(40deg);
              -moz-transform: rotate(40deg);
              -ms-transform: rotate(40deg);
              -o-transform: rotate(40deg);
              transform: rotate(40deg);
              transition-delay: 0.2s;
            }
            &:before {
              left: 0.125rem;
              top: 0.375rem;
              width: 0;
              height: 0.125rem;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }
            &:checked {
              background: #2f4154;
              //margin-right: 0.5rem;
              &:before {
                left: 0.0625rem;
                top: 0.625rem;
                width: 0.375rem;
                height: 0.125rem;
              }
              &:after {
                right: 0.3125rem;
                bottom: 0.0625rem;
                width: 0.125rem;
                height: 0.875rem;
              }
            }
          }
        }

        :deep img {
          width: 100%;
          border-radius: 5px;
        }

        :deep pre {
          font-size: 16px;
          padding: 0;
          margin: 0;

        }

        :deep code {
          &::-webkit-scrollbar-thumb {
            background-color: transparent;

          }
          &:hover {
            &::-webkit-scrollbar-thumb {
              background-color: rgba(0, 0, 0, 0.3);
            }
          }
        }

        :deep ol, :deep ul, :deep dl {
          //list-style: none;
          //margin: 0;
          padding-left: 1rem;
        }

        :deep .more {
          color: #cecece;
          width: 100%;
          display: inline-block;
          text-align: left;

        }

        :deep a {
          text-decoration: none;
          //color: inherit;
          //color: rgba(96, 183, 215, 0.51);
          //color: rgba(206, 206, 206, 0.6);
          color: var(--color-card-a-text);
          &:hover {
            //background-color: #efefef;
            text-decoration: underline;
          }
        }
        /* todo 随便兼容了一下，正常卡片里面不用h1和h2，因为卡片要轻，h1和h2太大了
        很多卡片结合成一篇文章的时候可以用h1或者h2来当标题 */
        :deep h1 {
          margin-top: 0;
          margin-bottom: 10px;
          color: var(--color-card-h1-text);
        }

        :deep h2 {
          margin-top: 0;
          color: var(--color-card-h2-text);
          padding-bottom: 5px;
          border-bottom: 2px solid var(--color-card-h2-border);
        }

        :deep h3 {
          color: var(--color-card-h3-text);
          padding-left: 5px;
          //border-bottom: 2px solid rgb(88 179 193 / 69%);
          border-left: 3px solid var(--color-card-h3-border);
          margin: 0
        }

        :deep blockquote {
          margin: 0;
          padding-left: 10px;
          border-left: 2px solid var(--color-card-blockquote-border);
          background: var(--color-card-blockquote-bg);
        }
      }
    }
    textarea {
      font-family: "Microsoft YaHei";
      color: #666;
      //margin-top: 35px; // 偏移出来头部的距离
      width: 100%;
      height: 200px;
      resize: none;
      font-size: 16px;
      border: 0;
      outline: none;
      //&:hover {
      //  border: 0;
      //  outline: none;
      //}
    }
    .vue-tags-input {

      width: 100%;
      max-width: 100%;
      border-bottom: 2px solid #cecece;
      :deep .ti-input {
        border: 0;
        //background-color: red;
      }
      :deep .ti-autocomplete {
        //background: #283944;
        border: 1px solid #8b9396;
        width: 500px;
        transform: translateX(-10px) translateY(4px);
        padding-bottom: 10px;
        padding-left: 10px;
        border-radius: 0 0 10px 10px;
        //transform: ;
        //border-top: none;
        border: 0;
        //display: flex;
        ul {
          display: flex;
          //background-color: red;
          .ti-item {
            //background-color: red;

          }
        }
      }
    }
    .colorTools {
      border-top: 1.5px solid rgba(178, 178, 178, 0.7);
      padding-top: 10px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      font-size: 10px;

      .colorSelect {
        z-index: 1;
        //border: 1px yellow solid;
        border: 0;
        margin: 0;
        width: 50px;
        padding: 0 10px;
        height: 25px;
        background-color: #dcdcdc;
        cursor: pointer;

        &.bg {
          padding-right: 2px;
          border-radius: 15px 0 0 15px;
        }
        &.text {
          padding-left: 2px;
          border-radius: 0 15px 15px 0;
        }
      }

    }
    // 颜色标签列表
    .colorList {
      //margin-left: 100px;
      //margin-top: 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .colorItem {
        display: inline-block;
        height: 20px;
        font-size: 10px;
        padding: 2px 10px;
        border-radius: 10px;
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
}

</style>
