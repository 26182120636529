/**
 * markdown文本渲染
 *
 * 使用方法let html = marked(markdownText)
 */

import { marked } from 'marked'
import hljs from 'highlight.js'

import * as markmap from 'markmap-view'

import { Transformer } from 'markmap-lib'
const transformer = new Transformer()

const escapeTest = /[&<>"']/
const escapeReplace = /[&<>"']/g
const escapeTestNoEncode = /[<>"']|&(?!#?\w+;)/
const escapeReplaceNoEncode = /[<>"']|&(?!#?\w+;)/g
const escapeReplacements = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;'
}
const getEscapeReplacement = (ch) => escapeReplacements[ch]
export function escape(html, encode) {
  if (encode) {
    if (escapeTest.test(html)) {
      return html.replace(escapeReplace, getEscapeReplacement)
    }
  } else {
    if (escapeTestNoEncode.test(html)) {
      return html.replace(escapeReplaceNoEncode, getEscapeReplacement)
    }
  }

  return html
}

marked.setOptions({
  highlight: function(code, lang) {
    const hljs = require('highlight.js')
    const language = hljs.getLanguage(lang) ? lang : 'plaintext'
    return hljs.highlight(code, { language }).value
  },
  langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
  breaks: true,
})

const renderer = {
  // string code, string infostring, boolean escaped
  code(code, infostring, escaped) {
    const lang = (infostring || '').match(/\S*/)[0]

    // console.log(code, infostring, escaped, lang, this.options.highlight)

    if (this.options.highlight) {
      const out = this.options.highlight(code, lang)
      if (out != null && out !== code) {
        escaped = true
        code = out
      }
    }

    code = code.replace(/\n$/, '') + '\n'

    if(lang === 'mind') {
      // 外面包裹一个mind-container是准备进行mind浮动的时候占位置
      return `<span class="mind-container"><svg class="mind" data-content="${code}"><svg></span>`
    }
    if (!lang) {
      return '<pre><code>' +
        (escaped ? code : escape(code, true)) +
        '</code></pre>\n'
    }

    return '<pre><code class="' +
      this.options.langPrefix +
      escape(lang, true) +
      '">' +
      (escaped ? code : escape(code, true)) +
      '</code></pre>\n' + `<span class="tag tag_code">#代码</span>` + `<span class="tag tag_code">#${escape(lang, true)}</span>`
  },
  listitem(text, task, checked) {
    if(task) {
      // 说明是选择框
      return `<li class="note-checkbox">${text}</li>\n`
    } else {
      return `<li>${text}</li>\n`
    }
  },
  checkbox(checked) {
    return '<input ' +
      (checked ? 'checked="" ' : '') +
      'type="checkbox"' +
      (this.options.xhtml ? ' /' : '') +
      '> '
  },
  link(href, title, text) {
    // href = cleanUrl(this.options.sanitize, this.options.baseUrl, href)
    if (href === null) {
      return text
    }
    let out = '<a target="_blank" href="' + href + '"'
    if (title) {
      out += ' title="' + title + '"'
    }
    out += '>' + text + '</a>'
    return out
  },
}

marked.use({ renderer })

/**
 * 匹配markdown中的 #标签
 */
const tag = {
  name: 'tag',
  level: 'inline', // Is this a block-level or inline-level tokenizer?
  start(src) { return src.match(/#/)?.index }, // Hint to Marked.js to stop and check for a match
  tokenizer(src, tokens) {
    const rule = /^#([^: \n]+)/ // Regex for the complete token, anchor to string start
    const match = rule.exec(src)
    if (match) {
      return { // Token to generate
        type: 'tag', // Should match "name" above
        raw: match[0], // Text to consume from the source
        dt: this.lexer.inlineTokens(match[1].trim()), // Additional custom properties, including
      }
    }
  },
  renderer(token) {
    return `<span class="tag tag_${this.parser.parseInline(token.dt)}" data-tag="${this.parser.parseInline(token.dt)}">#${this.parser.parseInline(token.dt)}</span>`
  }
}

marked.use({ extensions: [tag] })

export default marked
