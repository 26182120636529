<template>
  <div class="header">
    <div class="menu">
      <button class="fold" @click="foldMenu"><i class="fa fa-navicon"></i></button>
    </div>
    <div class="search">
      <input v-model="searchVal" autocomplete="off" @input="search" type="text">
    </div>
    <div class="buttons">
      <button @click="addNote" class="add">
        <i class="fa fa-plus"></i> 添加
      </button>
    </div>
  </div>
</template>

<script>
import { debounce, copyToClipbrd, decrypt, encrypt } from '@/utils/utils'
import html2canvas from 'html2canvas'

import md5 from 'md5'
import Toastify from 'toastify-js'
export default {
  name: 'Header',
  data() {
    return {
      searchVal: ''
    }
  },
  methods: {
    foldMenu() {
      this.$emit('foldMenu')
    },
    addNote() {
      this.$emit('addNote')
    },
    search() {
      debounce(() => {
        // console.log("输入的内容", this.searchVal)
        this.$emit('search', this.searchVal)
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  height: 50px;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fbfbfb; // #fbfbfb rgb(41, 149, 191)
  justify-content: space-evenly;
  .menu {
    display: flex;
    align-items: center;
    max-width: 500px;
    width: 60px;
    position: relative;
    .fold {
      background-color: transparent;
      position: fixed;
      left: 0;
      border: 0;
      width: 50px;
      height: 50px;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .search {
    //border: 1px solid red;
    display: flex;
    align-items: center;
    width: 500px;
    //flex: 1;
    input {
      width: 100%;
      height: 30px;
      border-radius: 15px;
      padding-left: 15px;
      background-color: #dcdcdc;
      //outline: none;
      border: 0;
      &:focus, &:active {
        //border: 0;
        outline: none;
      }
    }

  }
  .buttons {
    max-width: 500px;
    //border: 1px solid red;
    display: flex;
    align-items: center;
    .add {
      //background-color: transparent;
      flex-shrink: 0;
      border: 0;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      cursor: pointer;
      height: 30px;
      width: 60px;
      border-radius: 15px;
      background-color: #f5f5f5;
      &:hover {
        background-color: #dcdcdc; // #f5f5f5
      }
    }
  }

}
</style>
