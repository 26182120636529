<!--
基础的空白卡片
目前的功能：
1. 鼠标移入是否显示阴影
2. 主体内容插槽、头部和底部插槽
-->

<template>
  <div class="card" :style="cardStyle" :class="shadow ? 'is-shadow' : ''" @dblclick="cardDblClick" >
    <div class="el-card__header" v-if="$slots.header || header">
      <slot name="header">{{ header }}</slot>
    </div>
    <slot></slot>
    <div class="card__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    header: {},
    cardStyle: {
      type: Object,
      default: () => {
        return {
          background: '#fff'
        }
      }
    },
    shadow: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    cardDblClick(event) {
      this.$emit('dblclick', event)
    }
  }
}
</script>

<style lang="scss" scoped>
// 卡片框
.card {
  color: #333;
  //border-radius: 4px;
  margin: 10px 5px;
  padding: 10px;
  transition: height 0.5s ease-in-out; // height

  display: flex;
  flex-direction: column;

  &.is-shadow:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.17);
    &:deep del {
      background-color: rgba(88, 179, 193, 0.2);
    }
  }

  & .card__footer {
    //background: red;
  }
}

</style>
