/**
 * markdown解析混入
 * @module mixin/markedMixin
 */

import { marked } from 'marked'
import hljs from 'highlight.js'

import * as markmap from 'markmap-view'

import { Transformer } from 'markmap-lib'
const transformer = new Transformer()

const escapeTest = /[&<>"']/
const escapeReplace = /[&<>"']/g
const escapeTestNoEncode = /[<>"']|&(?!#?\w+;)/
const escapeReplaceNoEncode = /[<>"']|&(?!#?\w+;)/g
const escapeReplacements = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;'
}
const getEscapeReplacement = (ch) => escapeReplacements[ch]
export function escape(html, encode) {
  if (encode) {
    if (escapeTest.test(html)) {
      return html.replace(escapeReplace, getEscapeReplacement)
    }
  } else {
    if (escapeTestNoEncode.test(html)) {
      return html.replace(escapeReplaceNoEncode, getEscapeReplacement)
    }
  }

  return html
}

marked.setOptions({
  highlight: function(code, lang) {
    const hljs = require('highlight.js')
    const language = hljs.getLanguage(lang) ? lang : 'plaintext'
    return hljs.highlight(code, { language }).value
  },
  langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
  breaks: true,
})

const renderer = {
  // string code, string infostring, boolean escaped
  code(code, infostring, escaped) {
    const lang = (infostring || '').match(/\S*/)[0]

    // console.log(code, infostring, escaped, lang, this.options.highlight)

    if (this.options.highlight) {
      const out = this.options.highlight(code, lang)
      if (out != null && out !== code) {
        escaped = true
        code = out
      }
    }

    code = code.replace(/\n$/, '') + '\n'

    if(lang === 'mind') {
      // 外面包裹一个mind-container是准备进行mind浮动的时候占位置
      return `<span class="mind-container"><svg class="mind" data-content="${code}"><svg></span>`
    }
    if (!lang) {
      return '<pre><code>' +
        (escaped ? code : escape(code, true)) +
        '</code></pre>\n'
    }

    return '<pre><code class="' +
      this.options.langPrefix +
      escape(lang, true) +
      '">' +
      (escaped ? code : escape(code, true)) +
      '</code></pre>\n'
  },
  listitem(text, task, checked) {
    if(task) {
      // 说明是选择框
      return `<li class="note-checkbox">${text}</li>\n`
    } else {
      return `<li>${text}</li>\n`
    }
  },
  checkbox(checked) {
    return '<input ' +
      (checked ? 'checked="" ' : '') +
      'type="checkbox"' +
      (this.options.xhtml ? ' /' : '') +
      '> '
  },
  link(href, title, text) {
    // href = cleanUrl(this.options.sanitize, this.options.baseUrl, href)
    if (href === null) {
      return text
    }
    let out = '<a target="_blank" href="' + href + '"'
    if (title) {
      out += ' title="' + title + '"'
    }
    out += '>' + text + '</a>'
    return out
  }
}

marked.use({ renderer })

export default {
  methods: {
    /**
     * 将markdown的内容，处理后渲染到首页上面
     */
    renderMarkdownDeal(content) {
      // 寻找<!--more-->标签
      const pos = content.indexOf('<!--more-->')
      if(pos != -1) {
        content = content.substring(0, pos)
        content += `<span class="more"><i class="fa fa-chevron-up"></i>内容折叠</span>`
      }
      return this.markdown(content)
    },
    markdown(content) {
      return marked(content)
    },
    /**
     * 刷新代码中的mind
     * @param mindEl mind的dom结构
     * @param force 是否强制刷新
     */
    renderOneMind(mindEl, force = false) {
      if(!force) { // 只有不强制更新的情况下，才走这个判断
        if(mindEl.innerHTML !== '<svg></svg>') return
      }
      mindEl.innerHTML = '<svg></svg>'
      const { root } = transformer.transform(mindEl.getAttribute('data-content'))
      const { Markmap } = markmap

      try {
        Markmap.create(mindEl, undefined, root)
      } catch (e) {
        console.warn(e)
      }
    },
    renderMind() {
      for(const mind of document.getElementsByClassName('mind')) {
        this.renderOneMind(mind)
      }
    }
  },
  mounted () {
    this.renderMind()
  }
}
