<template>
  <div class="container" @click="mainClick">
    <Header @addNote="addNote" @search="search" @foldMenu="foldMenu" @listMode="listMode" ref="headerRef"></Header>
    <div class="content">
      <Menu v-show="menuShow"
            @changeFilterRulesState="changeFilterRulesState"
            @clearFilterRulesTags="clearFilterRulesTags"
            @clearFilterRulesIncludeTags="clearFilterRulesIncludeTags"
            @refresh="init"
            :active="filterRules.status[0]"
            :allTags="allTagsArr"
            :filterRules="filterRules"></Menu>
      <div id="main" class="main">
        <div class="message" v-if="notesList.length === 0">没有任何笔记，请添加</div>
        <div class="column" v-for="(column,index) in columns" :key="index">
          <NoteCard
            class="note-card"
            v-for="item in column.columnArr"
            :item="item" :key="item.id"
            @editNote="edit"
            @tagClick="tagClick"
            @exportOneNoteLog="exportOneNoteLog"
            @reviewNote="reviewNote"
            @saveNotes="saveNotes"
            :option="noteCardOption"
          ></NoteCard>
        </div>
      </div>
    </div>
    <!--弹出-编辑框-->
    <EditDialog ref="editDialogRef" @editClose="editClose" :editItem.sync="editItem" :editTag.sync="editTag" v-if="editShow"></EditDialog>

    <ReviewDialog v-if="reviewShow" :notes="reviewNoteData" @reviewClose="reviewClose"></ReviewDialog>
    <SettingDialog v-if="settingShow"></SettingDialog>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import Header from '@/views/Index/Header'
import Menu from '@/views/Index/Menu'
import { encrypt, decrypt, debounce, copyRightConsole, SeedRand } from '@/utils/utils'
import EditDialog from '@/views/Index/EditDialog'
import ReviewDialog from '@/views/Index/ReviewDialog'
import NoteCard from '@/views/Index/NoteCard'
import markedMixin from '@/mixin/markedMixin'
import noteLogsApi from '@/api/noteLogs'
import logMixin from '@/mixin/logMixin'
import SettingDialog from '@/views/Index/SettingDialog'
import saveLoadMixin from '@/mixin/saveLoadMixin'
/**
 * 笔记系统主入口文件
 * @module IndexView
 */
export default {
  name: 'IndexView',
  mixins: [markedMixin, logMixin, saveLoadMixin],
  components: { SettingDialog, NoteCard, ReviewDialog, EditDialog, Menu, Header },
  data() {
    return {
      allTags: {}, // 文章中所有的标签
      allTagsArr: [], // 文章中所有的标签数组
      menuShow: true,
      notesList: [],
      renderNumber: 0, // init时渲染多少条笔记
      noteCardOption: {}, // 笔记显示参数
      randomSeed: null, // 随机发生器的种子
      colors: [
        { background: '#a81746', color: '#fff', text: '深红' },
        { background: '#C8625D', color: '#fff', text: '红色' },
        { background: '#fdf8b3', color: '#484848', text: '黄色' },
        { background: '#56b9ba', color: '#fff', text: '天蓝' },
        { background: '#058aaa', color: '#fff', text: '蓝色' },
        { background: '#92cc77', color: '#fff', text: '绿色' },
        { background: '#18856d', color: '#fff', text: '深绿' },
      ],

      columns: [],
      arrIndex: [],
      loading: false,
      contentArr2: [],
      editShow: false, // 编辑框是否展示
      editItem: {}, // 编辑框内容
      editItemCopy: {}, // 编辑内容的深拷贝
      editTag: {
        tag: '',
        tags: [],
        autocomplete: [] // { text: '' }
      },
      filterRules: {
        status: [0],
        secret: false, // 是否显示私人笔记，true 表示显示笔记secret为true的笔记
        includeText: '',
        tags: [], // ['网址'] 且的关系，显示只有这些标签的文章
        includeTags: [], // 包含关系，显示所有有这个标签内容的笔记
        shuffle: false, // 是否打乱顺序显示
      },
      reviewShow: false,
      settingShow: false,
      reviewNoteData: {}
    }
  },
  watch: {
    /**
     * 如果拉取更新的变量发生了变化，那么说明可能有内容要更新，进行初始化
     * @param val
     */
    "$store.state.contentArr": function (val) {
      this.init()
    },
    // "$store.state.pullNoteLogFlag": function(val) {
    //   if(val === false) {
    //     this.init()
    //   }
    // },
    /**
     * 监听过滤标签的变化，发生变化以后修改query参数
     * @param val
     */
    "filterRules.tags": function (val) {
      let query = { ...this.$route.query }
      if(val.length) {
        query = { ...query, tags: val.join(',') }
      } else {
        delete query.tags
      }
      this.$router.push({ query })
    },
    /**
     * 监听包含标签的变化，发生变化以后修改query参数
     * @param val
     */
    "filterRules.includeTags": function(val) {
      let query = { ...this.$route.query }
      if(val.length) {
        query = { ...query, includeTags: val.join(',') }
      } else {
        delete query.includeTags
      }
      this.$router.push({ query })
    }
  },
  computed: {
    // notesList() {
    //
    // }
  },
  methods: {
    /**
     * 切换为列表模式
     * @function listMode
     */
    listMode() {
      this.reviewShow = true
      this.reviewNoteData = this.notesList
    },
    /**
     * 复习模式
     * @function reviewNote
     * @param note
     */
    reviewNote(note) {
      this.reviewShow = true
      this.reviewNoteData = [note]
    },
    /**
     * 复习模式关闭
     */
    reviewClose() {
      this.reviewShow = false
    },
    /**
     * 导出一个笔记的日志
     * @function exportOneNoteLog
     * @param item {Object} 笔记内容
     */
    exportOneNoteLog(item) {
      this.$refs.headerRef.exportLogs({ id: item.id })
    },
    /**
     * 收缩展开菜单
     * @function foldMenu
     */
    foldMenu() {
      this.menuShow = !this.menuShow
      this.$nextTick(() => {
        this.init()
      })
    },

    /**
     * 标签点击
     */
    tagClick(tagName) {
      if(!this.filterRules.tags.includes(tagName)) {
        this.filterRules.tags.push(tagName)
      } else {
        this.filterRules.tags = this.filterRules.tags.filter(name => name !== tagName)
      }
      this.init()
    },
    /**
     * 清空筛选规则中的【只包含】标签
     */
    clearFilterRulesTags() {
      if(this.filterRules.tags) { // 防止不必的刷新
        this.filterRules.tags = []
        this.init()
      }
    },
    /**
     * 清空筛选规则中的【包含】标签
     */
    clearFilterRulesIncludeTags() {
      if(this.filterRules.includeTags) { // 防止不必的刷新
        this.filterRules.includeTags = []
        this.init()
      }
    },
    /**
     * 清空筛选规则中的tags
     */
    clearFilterRulesincludeText() {
      this.filterRules.includeText = ''
      this.$refs.headerRef.searchVal = ''
      this.init()
    },
    /**
     * 点击空白处，清空搜索内容和筛选结果
     * @param event
     */
    mainClick(event) {
      if(['menu', 'column', 'main'].includes(event.target.className)) {
        // 搜索框有内容，先清空搜索框
        if(this.$refs.headerRef.searchVal) {
          this.clearFilterRulesincludeText()
          return
        }
        if(this.$store.state.setting.clearFilterRulesTags) {
          this.clearFilterRulesTags()
        }
        if(this.$store.state.setting.clearFilterRulesIncludeTags) {
          this.clearFilterRulesIncludeTags()
        }

      }
      // console.log("main点击", event.target.className)
      debounce(() => {
        this.pullNoteLog()
      }, this.$store.state.setting.updatePullTime)
    },
    /**
     * 搜索笔记中的内容
     */
    search(val) {
      this.filterRules.includeText = val
      this.init()
    },
    // 删除笔记中的a标签的href
    deleteATag() {
      if (!this.$store.state.setting.deleteATagHref) return
      this.$nextTick(() => {
        const els = document.getElementsByClassName("item")
        for (const el of els) {
          if (el) {
            const a = el.getElementsByClassName('text')[0].getElementsByTagName('a')[0]
            if (a) {
              a.removeAttribute('href')
            }
            // console.log(item)
          } else {
            console.warn("获取不到el")
          }
        }
      })
    },
    /**
     * 改变笔记高度
     */
    changeHeight(item) {
      this.init()
      this.$nextTick(() => {
        const el = document.getElementById("note_" + item.id)
        if (el) {
          // console.log("高度", el.clientHeight)
          const nowHeight = el.clientHeight
          if(item.height !== nowHeight) {
            this.addLog({ option: 'EditNote', note: { id: item.id, height: nowHeight } })
            item.height = el.clientHeight
          }
          // console.log(item)
        } else {
          console.warn("获取不到el")
        }
      })
    },
    /**
     * 通过id获取笔记
     *
     * @name 通过id获取笔记
     * @function getNoteById
     * @param id {String} 笔记id
     * @example
     * const noteObj = this.getNoteById(id)
     * @returns {Object} `{ note: 笔记内容, index: this.$store.state.contentArr数组下标 }`
     */
    getNoteById(id) {
      for(const index in this.$store.state.contentArr) {
        if(this.$store.state.contentArr[index].id === id) {
          return { note: this.$store.state.contentArr[index], index }
        }
      }
      return null
    },

    /**
     * 将log日志的操作合并到笔记中
     *
     * @summary 日志合并到笔记
     * @function NoteLogsToNotes
     */
    NoteLogsToNotes() {
      // 将日志整理一下，整理完毕以后，再用
      this.arrageLog()
      let NoteLogs = []
      if(localStorage.getItem("NoteLogs")) {
        NoteLogs = localStorage.getItem("NoteLogs")
        NoteLogs = decrypt(NoteLogs)
        NoteLogs = JSON.parse(NoteLogs)
      }
      this.$store.state.logNum = NoteLogs.length
      this.$store.state.noteLogs = NoteLogs
      // console.log("NoteLogs", NoteLogs)
      // 遍历日志，获取对应的操作
      for(const log of NoteLogs) {
        const option = log.option
        if(option === 'AddNote') {
          const id = log.data.note.id

          // 判断笔记是否存在 如果存在直接结束
          if(this.getNoteById(id)) {
            // console.log("笔记存在 --------- " + id)
            continue
          }
          this.refreshSmartTags(log.data.note)
          // 笔记不存在，创建笔记
          this.$store.state.contentArr.push(log.data.note)

        } else if(option === 'EditNote') {
          const id = log.data.note.id

          const logNote = log.data.note

          // 判断笔记是否存在 笔记不存在，抛出异常
          const noteObj = this.getNoteById(id)
          if(!noteObj) {
            console.error("笔记不存在 --------- 说明创建笔记的日志丢失了, 更新的内容为", log.data.note)
            continue
          }
          let note = noteObj.note
          const index = noteObj.index

          // 笔记存在，对比更新时间，如果笔记的更新时间 >= 日志的时间 直接结束
          if(note.updateAt >= log.createAt) {
            // console.log("本条日志时间过期")
            continue
          }

          // 用日志中的字段替换笔记中的，并且把更新时间也修改为日志的创建时间 这里的时间可能不太精确，应该用笔记的更新时间，不过目前不影响
          note = { ...note, ...logNote }

          // note.updateAt = log.createAt

          if(this.$store.state.contentArr[index].id === note.id) {
            // console.log(this.$store.state.contentArr[index])
            this.refreshSmartTags(note)
            this.$store.state.contentArr[index] = note
            // console.log(`修改笔记 ${id}`)

          }
        }
      }
      // this.saveNotes()
    },
    /**
     * 保存所有笔记
     */
    saveNotes() {
      this.init()
      debounce(() => {
        console.time('saveNotes保存笔记用时')
        // this.NoteLogsToNotes()
        localStorage.setItem('ideaNotes', JSON.stringify(this.$store.state.contentArr))
        console.log("保存笔记到localStorage")
        this.saveSetting()
        console.timeEnd('saveNotes保存笔记用时')
      }, 0)

    },
    /**
     * 载入所有笔记
     */
    loadNotes() {
      // console.log("json", localStorage.getItem('ideaNotes') || '[]')
      this.$store.state.contentArr = JSON.parse(localStorage.getItem('ideaNotes') || '[]')
      this.NoteLogsToNotes()
    },
    /**
     * 获取笔记卡片中的智能标签
     * @param item
     * @returns {{}}
     */
    getSmartTags(item) {
      const tags = {}
      for(const smartTag of this.$store.state.setting.smartTags) {
        const reg = smartTag.regex
        let res = item.content.search(RegExp(reg))
        if(res === -1) {
          // 没找到，继续看看html有没有
          res = this.markdown(item.content).search(RegExp(reg))
        }
        if(res !== -1) {
          // console.log("找到智能标签", smartTag.text)
          tags[smartTag.text] = { background: smartTag.background, color: smartTag.color, smart: true }
        }
      }
      return tags
    },
    // todo refreshSmartTags 不是很好的写法，后期用getSmartTags函数替代
    /**
     * 刷新note中的智能标签
     * @param item
     */
    refreshSmartTags(item) {

      if(!item.tags) {
        console.log("没有标签字段，添加标签字段，这个判断可以删除")
        item.tags = {}
      }
      if(item.tags.constructor !== Object) {
        console.log("标签字段格式不对")
        item.tags = {}
      }
      // 移除item中的所有的智能标签； 因为标签的key是标签名称，所以不会出现重复的情况，因此没必要移除；也需要移除，因为内容改变以后可能这个标签不存在了
      for(const tagName in item.tags) {
        if(item.tags[tagName].smart) {
          // 是智能标签，进行删除
          delete item.tags[tagName]
        }
      }
      // todo 这里是有一条日志出现了问题，正常不会出现这个问题，后续可以把这个判断给去掉
      if(!item.content) {
        item.content = ""
      }
      // 给item添加智能标签
      for(const smartTag of this.$store.state.setting.smartTags) {
        // console.log("happen->智能标签", smartTag.text)
        const reg = smartTag.regex
        let res = item.content.search(RegExp(reg))
        if(res === -1) {
          // 没找到，继续看看html有没有
          res = this.markdown(item.content).search(RegExp(reg))
        }
        if(res !== -1) {
          // console.log("找到智能标签", smartTag.text)
          item.tags[smartTag.text] = { background: smartTag.background, color: smartTag.color, smart: true }
        }
        // console.log("正则表达式搜索结果", res)

      }

    },
    /**
     * 判断vue-tags组件中的内容，返回需要插入文章中的标签格式内容
     */
    getNewTags() {
      // 新修改的标签
      const newTags = {}
      for(const tag of this.editTag.tags) {
        // const { text, excludeText } = tag
        newTags[tag.text] = { background: '#5c6bc0', color: '#ffffff', smart: false }
      }
      // console.log("笔记标签", this.editTag)
      // 看看没保存的内容
      if(this.editTag.tag) {
        newTags[this.editTag.tag] = { background: '#5c6bc0', color: '#ffffff', smart: false }
      }
      return newTags
    },
    /**
     * 查看两个笔记之间的区别
     * @param oldNote
     * @param newNote
     */
    getNoteDiff(oldNote, newNote) {
      // console.log("diffNote", oldNote, newNote)
      let deffNote = {}
      // Object.keys(newNote).forEach(key => {
      //   if(oldNote[key] !== newNote[key]) {
      //     deffNote = { ...deffNote, [key]: newNote[key] }
      //   }
      // })
      // 查看内容是否一样
      if(newNote.content !== oldNote.content) {
        deffNote = { ...deffNote, 'content': newNote.content }
      }
      // 查看高度是否一样
      // if(newNote.content !== oldNote.content) {
      //   deffNote = { ...deffNote, 'content': newNote.content }
      // }

      // 查看标签是否一样
      // 原来文章中的标签
      const oldTags = {}
      for(const tagName in oldNote.tags) {
        const tag = oldNote.tags[tagName]
        if(!tag.smart) {
          oldTags[tagName] = tag
        }
      }

      // 新修改的标签
      const newTags = this.getNewTags()
      // for(const tag of this.editTag.tags) {
      //   // const { text, excludeText } = tag
      //   newTags[tag.text] = { background: '#5c6bc0', color: '#ffffff', smart: false }
      // }
      // console.log("笔记标签", this.editTag)
      // // 看看没保存的内容
      // if(this.editTag.tag) {
      //   newTags[this.editTag.tag] = { background: '#5c6bc0', color: '#ffffff', smart: false }
      // }
      // 新老标签添加的时候为什么会一样，添加的时候，oldTags应该是什么都没有的，添加的时候不用进行diff函数的对比，写什么就是什么
      // console.log("新老标签", oldTags, newTags, JSON.stringify(oldTags) !== JSON.stringify(newTags))
      if(JSON.stringify(oldTags) !== JSON.stringify(newTags)) {
        newNote.tags = { ...this.getSmartTags(newNote), ...newTags }
        deffNote = { ...deffNote, 'tags': newTags }
      }

      // console.log(deffNote)
      return deffNote
    },
    /**
     * 此方法会遍历所有的日志，然后对日志进行一些操作，目前进行的操作如下
     *
     * 1. 如果日志没有id，则加入id，此步骤是兼容性的写法，后续可以删除
     * 2. 对日志按照创建时间进行排序，方便对日志进行按时间线的合并操作
     * @todo 对日志进行时间排序，并且进行合并对服务器同步很不友好，后续可以考虑将这个代码优化一下
     * @summary 整理日志文件
     */
    arrageLog() {
      let NoteLogs = []
      if(localStorage.getItem('NoteLogs')) {
        NoteLogs = JSON.parse(decrypt(localStorage.getItem('NoteLogs'))) || []
      }

      NoteLogs.forEach(log => {
        if(!log.id) { // todo 如果日志没有id，则需要添加id，这个以后可以去掉
          const id = nanoid() // => "V1StGXR8_Z5jdHi6B-myT"
          log.id = id
        }
        // console.log(log)
      })
      NoteLogs = NoteLogs.sort((a, b) => a.createAt - b.createAt)
      localStorage.setItem('NoteLogs', encrypt(JSON.stringify(NoteLogs)))
    },
    /**
     * 编辑框关闭事件
     * @function editClose
     */
    editClose() {
      this.editShow = false
      // console.log("关闭")
      // if(diffNote) {
      //   console.log("有不一样的")
      //   return false
      // }
      // 里面有不一样的东西

      // console.log("happen->编辑了内容", diffNote, (this.editItem.content && JSON.stringify(diffNote) !== '{}'))
      // 有id说明是编辑
      if (this.editItem.id) {
        const diffNote = this.getNoteDiff(this.editItemCopy, this.editItem)
        if (JSON.stringify(diffNote) !== '{}') {
          this.editItem.updateAt = new Date().getTime()
          this.addLog({ option: 'EditNote', note: { ...diffNote, id: this.editItem.id, updateAt: this.editItem.updateAt } })
          console.log("编辑保存成功")
        }
      } else { // 创建笔记
        const id = nanoid() // => "V1StGXR8_Z5jdHi6B-myT"
        const createAt = new Date().getTime()
        this.editItem = {
          ...this.editItem,
          id,
          createAt: createAt,
          updateAt: createAt
        }
        // 添加标签
        this.editItem.tags = this.getNewTags()
        console.log("添加的内容", this.editItem)
        if(this.editItem.content) { // 内容不空
          this.addLog({ option: 'AddNote', note: this.editItem })
          this.$store.state.contentArr.push(this.editItem)
        }
      }
      // this.arrageLog(this.editItem)

      this.$nextTick(() => {
        this.changeHeight(this.editItem)
        this.refreshSmartTags(this.editItem)
        this.bindCheckboxEventByElement(document.getElementById("note_" + this.editItem.id))
        this.saveNotes()
      })
      // this.editItem = {}
      // console.timeEnd("editClose")
    },
    /**
     * 点击添加笔记后，调用的方法，此方法会打开编辑框，并且创建一个空白的笔记对象，等待用户写入笔记内容
     *
     * 具体的笔记保存和后续的处理，调用的是edit方法
     *
     * @summary 添加笔记
     * @function addNote
     */
    addNote() {
      console.log("添加note")
      this.editShow = true
      this.editTag.tags = []
      this.editTag.tag = ''
      const note = {
        content: "",
        background: '#ffffff', // 背景颜色
        color: '#333333', // 字体颜色
        top: '',
        tags: {}, // { '网址': { background: '#56b9ba', color: '#fff', smart: false } }, // 智能标签，智能标签每次更新文本的时候会自动删除，然后根据文本内容来自动生成标签
        state: 0, // 0-正常 1-删除 2-归档
        secret: false, // 是否隐藏
        type: '', // 笔记类型
        height: 100,
      }
      // 这个代码是用来将当前选中的标签，同步加入到新创建的笔记中的
      if(this.filterRules.tags.length) {
        // 将过滤器中的[只包含]标签插入编辑框的标签中
        for(const tagName of this.filterRules.tags) {
          // console.log("过滤器中的标签", tagName)
          // const tag = item.tags[tagName]
          // if(!tag.smart) {
          this.editTag.tags.push({ text: tagName, style: { background: '#5c6bc0', color: '#ffffff' } })
          // }
        }
      } else if(this.filterRules.includeTags.length) {
        // 如果没有只包含标签，那么把包含的第一个插入到过滤器中
        this.editTag.tags.push({ text: this.filterRules.includeTags[0], style: { background: '#5c6bc0', color: '#ffffff' } })
      }
      this.editItem = note
      this.$nextTick(() => {
        this.$refs.editDialogRef.changeEditHeight()
      })

    },
    /**
     * 编辑笔记
     * @param item
     */
    edit(item) {
      // console.log(item)
      console.log("编辑笔记")
      this.editShow = true
      this.editItemCopy = JSON.parse(JSON.stringify(item))
      this.editItem = item
      this.editTag.tags = []
      this.editTag.tag = ''
      this.$nextTick(() => {
        this.$refs.editDialogRef.changeEditHeight()
      })
      // 将标签插入编辑框的标签中
      for(const tagName in item.tags) {
        const tag = item.tags[tagName]
        if(!tag.smart) {
          this.editTag.tags.push({ text: tagName, style: { background: tag.background } })
        }
      }
      // console.log(item.tags)
    },
    /**
     * 懒加载剩余的内容
     * @returns {Promise<unknown>}
     */
    pushElement() {
      return new Promise((resolve, reject) => {
        // setTimeout(() => {
        this.contentArr2 = []
        const start = this.renderNumber
        let end = start + 50
        if(end >= this.notesList.length) {
          end = this.notesList.length
        }
        // console.log(`start: ${start}, end: ${end}`)
        for(let i = start; i < end; i++) {
          this.contentArr2.push(this.notesList[i])
        }
        this.renderNumber = end
        resolve()
        // }, 1000)
      })
    },
    /**
     * 获取高度最小的那一列的高度
     * @param arr
     * @returns {number}
     */
    getMinHeight(arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i].height) + parseInt(arr[i].top))
      }
      return Math.min.apply(null, a)
    },
    /**
     * 获取高度最小的那一列的索引
     * @param val
     */
    getMinIndex(val) {
      for (let i = 0; i < this.columns.length; i++) {
        const height = this.columns[i].columnArr[this.columns[i].columnArr.length - 1].height
        const top = this.columns[i].columnArr[this.columns[i].columnArr.length - 1].top
        if (parseInt(height) + parseInt(top) == val) {
          this.arrIndex.push(i)
        }
      }
    },

    // 改变过滤规则中的state
    changeFilterRulesState(state) {
      this.filterRules.status = state
      this.init()
    },
    /**
     * 过滤所有的笔记
     */
    filterNote() {
      const tagSet = new Set()
      this.allTags = {}
      // 过笔记状态 正常 删除 还是归档
      this.notesList = this.$store.state.contentArr.filter(item => {
        if (this.filterRules.status.includes(item.state)) {
          // 蹭一蹭，顺带把标签给提取出来
          for(const tagName in item.tags) {
            tagSet.add(tagName)
            if(this.allTags[tagName]) {
              // 所有标签里面有这个标签
              this.allTags[tagName].count++
            } else {
              // 所有标签里面没有这个标签
              this.allTags[tagName] = { ...item.tags[tagName], count: 1, tagName }
            }
          }

          return true
        }
        return false
      })

      // console.log('所有标签', allTags)

      // 将需要排序的 key, 按照 "从小到大" 进行排列
      const sortKeys = Object.keys(this.allTags).sort((a, b) => {
        return this.allTags[b].count - this.allTags[a].count
      })
      this.allTagsArr = []
      // 循环排列好的 key, 重新组成一个新的数组
      for (const sortIndex in sortKeys) {
        this.allTagsArr.push(this.allTags[sortKeys[sortIndex]])
      }
      // console.log('allTagsArr', this.allTagsArr)

      // 将标签列表筛选出来
      this.editTag.autocomplete = []
      for(const tagName of tagSet) {
        this.editTag.autocomplete.push({ text: tagName })
      }

      // 判断是否隐藏
      if(this.filterRules.secret === false) { // 公开模式下
        this.notesList = this.notesList.filter(item => {
          if (item.secret) { // item.secret 表示笔记是否是私人笔记
            return false // 是私人笔记不显示
          }
          return true
        })
      }

      if(this.filterRules.includeTags.length) {
        // 如果是包含模式的话，先进性过滤
        this.notesList = this.notesList.filter(item => {
          // 判断每个标签
          for(const tagName in item.tags) {
            if(this.filterRules.includeTags.includes(tagName)) {
              return true
            }
          }
          return false
        })
      }

      // 进行搜索过滤 以后可以优化，可以和上面的合并
      this.notesList = this.notesList.filter(item => {
        // 判断内容
        if (item.content.toLowerCase().indexOf(this.filterRules.includeText.toLowerCase()) !== -1) {
          return true
        }
        // 判断每个标签
        for(const tagName in item.tags) {
          if(tagName.toLowerCase().indexOf(this.filterRules.includeText.toLowerCase()) !== -1) {
            return true
          }
        }
        // 将搜索结果中的空格断开，进行细致的搜索
        let count = 0
        const splitTextList = this.filterRules.includeText.toLowerCase().split(' ').filter(t => t != '')
        const splitCount = splitTextList.length
        for(const textItem of splitTextList) {
          if (item.content.toLowerCase().indexOf(textItem) !== -1) {
            count++
          }
        }
        if(count == splitCount) {
          return true
        }
        return false
      })

      // 进行标签筛选 连续点标签，然后会更精确
      if(this.filterRules.tags.length) {
        this.notesList = this.notesList.filter(item => {
          let count = 0

          for(const tagName of this.filterRules.tags) {
            // console.log(tag, (item.tags && item.tags[tag] !== undefined) == true )
            if(item.tags && (item.tags[tagName] !== undefined)) {
              // console.log("tree")
              // console.log(item.content)
              count++
              // return true
            }
          }
          if(count === this.filterRules.tags.length) {
            return true
          }
          return false
        })
      }
      // 展示文章的列表如何渲染，随机，还是按一定的顺序
      // todo: 目前存在的问题是在打乱的状态下，如果添加了新的文章，会进行打乱，后续可以进行优化，可以将前几个脱离打乱的规则，一直按照时间进行排序
      if(this.filterRules.shuffle) {
        if(!this.randomSeed) { // 设置一个随机种子，在这次随机的过程中，保持文章的序列不变
          this.randomSeed = Date.now()
        }
        // 自己写的一个随机数发生器，给定固定的种子可以产生固定的序列
        const seedRand = new SeedRand({ seed: this.randomSeed, be: 5 })
        function randomSort(a, b) { return seedRand.next(1) > 0.5 ? -1 : 1 }
        this.notesList.sort(randomSort)
      } else {
        this.randomSeed = null // 初始化随机的种子
        // 进行排序 按照添加日期进行排序
        function createAtSort(a, b) { return b.createAt - a.createAt }
        this.notesList.sort(createAtSort)
      }
    },

    /**
     * 改变note中checkbox状态
     * @param noteId 笔记id
     * @param checkboxIndex 笔记中第几个checkbox
     */
    changeCheckbox(noteId, checkboxIndex) {
      const that = this
      function change(note) {
        const reg = /(?:- \[x\].)|(?:- \[ \].)/gi
        let findReg = []
        let v
        let pos = 0
        while ((v = reg.exec(note.content))) {
          findReg = v
          if(pos === checkboxIndex) break
          pos++
        }
        const currentCheckBox = findReg[0] // - [x]
        const begin = findReg.index // 0
        const Map = ['- [ ] ', '- [x] ']

        if(currentCheckBox === '- [x] ') {
          // 选中，需要取消
          note.content = note.content.substring(0, begin) + Map[0] + note.content.substring(begin + Map[0].length)
        } else {
          note.content = note.content.substring(0, begin) + Map[1] + note.content.substring(begin + Map[1].length)
        }
        note.updateAt = new Date().getTime()
        that.addLog({ option: 'EditNote', note: { content: note.content, id: note.id, updateAt: note.updateAt } })
        that.$nextTick(() => {
          that.bindCheckboxEventByElement(document.getElementById("note_" + note.id))
        })
        // that.bindCheckboxEvent()
        // that.saveNotes()
      }

      // console.log(noteId, checkboxIndex)
      for(const note of this.notesList) {
        if(noteId.indexOf(note.id) !== -1) {
          // console.log(note)
          change(note)
          break
        }
      }
    },
    /**
     * 给文章中的checkbox选择框绑定事件
     * @param noteEl
     */
    bindCheckboxEventByElement(noteEl) {
      if(!noteEl) return
      const checkboxs = noteEl.querySelectorAll('.note-checkbox input[type=checkbox]')
      if(!checkboxs) return
      const id = noteEl.id
      if(checkboxs.length) {
        // console.log(checkboxs)
        checkboxs.forEach((checkbox, index) => {
          const that = this
          function checkboxFun(event) {
            that.changeCheckbox(id, index)
            // this.saveNotes()
            event.stopPropagation()
          }
          checkbox.removeEventListener('click', checkboxFun)

          checkbox.addEventListener('click', checkboxFun)
        })
      }
    },
    /**
     * 遍历所有有checkbox的文章，然后绑定checkbox框事件
     */
    bindCheckboxEvent() {
      this.$nextTick(() => {
        const noteEls = document.querySelectorAll('.item')
        noteEls.forEach(noteEl => {
          // const checkboxCheckeds = noteEl.querySelectorAll('.note-checkbox input[type=checkbox]:checked')
          this.bindCheckboxEventByElement(noteEl)
          // if(checkboxCheckeds.length) {
          //   checkboxCheckeds.forEach((checkbox, index) => {
          //     checkbox.removeEventListener('click', () => {})
          //     checkbox.addEventListener('click', (event) => {
          //       console.log("点击了", id, index)
          //       this.changeCheckbox(id, true, index)
          //       event.stopPropagation()
          //     })
          //   })
          // }
        })
      })
    },
    /**
     * 对整个内容进行数据的渲染，开销很大，但是目前的做法每进行一步操作都会执行这个函数
     * 后续可以拆分这个函数，将刷新文章的内容和排列文章的位置进行拆分
     */
    init() {
      console.time('init初始化用时')
      this.filterNote()

      this.columns = []
      const contentLen = this.notesList.length
      // 根据可视区域的宽度判断需要几列
      // let cWidth = document.documentElement.clientWidth || document.body.clientWidth;
      const mainEl = document.getElementById('main')
      if(!mainEl) {
        console.log('没有找到main元素，直接接触init初始化')
        console.timeEnd('init初始化用时')
        return
      }
      const cWidth = mainEl.clientWidth

      // 假设图片宽度为100px
      let cLen = Math.floor((cWidth / (15 * 16))) // 计算有多少列
      if (cLen > this.notesList.length) {
        cLen = this.notesList.length
      }
      // console.log('列的长度', cLen, this.$store.state.contentArr.length)
      if(cLen === 0) return

      // 初始化每一列的第一行元素
      for (let i = 0; i < cLen; i++) {
        this.notesList[i].top = 0 // 预设距离顶部值为0
        this.columns.push({ columnArr: [this.notesList[i]] })
      }

      // 进行分页操作 只渲染前50条，那么剩下的进行懒加载 contentLen
      this.renderNumber = 50
      if(this.renderNumber >= contentLen) {
        this.renderNumber = contentLen
      }
      // 对剩余元素的判断，应该放到哪一列
      for (let index = cLen; index < this.renderNumber; index++) {
        this.arrIndex = []
        const arr = [] // 找到高度最小的一列，可能有多个
        let minHeight = 0 // 高度最小的一列的高度
        let pushIndex = 0 // 高度最小的一列所在位置的索引

        for (let i = 0; i < this.columns.length; i++) {
          arr.push({
            height: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].height,
            top: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].top
          })
        }

        minHeight = this.getMinHeight(arr)
        this.getMinIndex(minHeight)
        if (this.arrIndex.length > 0) {
          pushIndex = Math.min.apply(null, this.arrIndex) // 出现高度一样的，去索引最小的
        }

        this.notesList[index].top = minHeight + 20
        this.columns[pushIndex].columnArr.push(this.notesList[index])
      }
      // this.deleteATag()
      console.timeEnd('init初始化用时')
    },
  },
  created() {
    this.loadSetting() // 先载入设置
    this.loadNotes()
    // 判断屏幕宽度，如果太窄了，自动把菜单收起来
    const clientW = document.documentElement.clientWidth || document.body.clientWidth
    if(clientW < 500) {
      this.menuShow = false
    }
    // 输出版本信息
    copyRightConsole()
    /* 监听键盘事件
    var lett = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      console.log(key)
      if (key == 13) {
        lett.enterSearchMember();
      }
    } */
  },
  mounted() {
    // 将url中的query放到data变量中
    const query = this.$route.query
    // 处理访问的url中带的标签参数
    if(query.tags) {
      // console.log(query.tags)
      this.filterRules.tags = query.tags.split(',')
    }
    // 处理访问的url中带的包含标签参数
    if(query.includeTags) {
      // console.log(query.includeTags)
      this.filterRules.includeTags = query.includeTags.split(',')
    }

    this.init()
    // 窗口大小发生改变
    window.onresize = () => {
      // console.time('aa')
      this.init()
      // console.timeEnd('aa')
    }
    // 绑定缩进事件
    this.bindCheckboxEvent()

    const viewEl = document.getElementById('main')
    // 打开笔记系统以后，需要进行笔记的上传和下载
    setTimeout(() => {
      // 每分钟自动上传日志到服务器
      this.$store.state.updateInterval = setInterval(() => {
        // 上传日志到服务器
        this.updateAllLogs()
        const second = new Date().getSeconds()
        if(second > 0 && second < 5) {
          console.log()
          this.saveSetting()
        }
      }, 10)
      this.pullNoteLog() // 打开笔记系统以后，自动拉取一下日志
      // todo 自动拉取不太好，改成只要进行了一些操作，那么进行拉取
      // this.$store.state.pullInterval = setInterval(() => {
      //   this.pullNoteLog()
      // }, this.$store.state.setting.updatePullTime)

    }, 1000)

    // 下面是懒加载代码
    viewEl.onscroll = (e) => {
      const top = viewEl.scrollTop
      const clientH = viewEl.clientHeight

      const height = viewEl.scrollHeight
      // console.log(top, clientH, height, top + clientH)

      if ((top + clientH) >= height - 1) {
        this.loading = true
        this.pushElement().then(() => {
          //  从接口最新获取的元素push到目前高度最小的一列
          if(!this.contentArr2.length) {
            console.log("加载完毕了")
            return
          }
          for (let index = 0; index < this.contentArr2.length; index++) {
            this.arrIndex = []
            const arr = [] // 找到高度最小的一列，可能有多个
            let minHeight = 0 // 高度最小的一列的高度
            let pushIndex = 0 // 高度最小的一列所在位置的索引

            for (let i = 0; i < this.columns.length; i++) {
              arr.push({
                height: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].height,
                top: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].top
              })
            }

            minHeight = this.getMinHeight(arr)
            this.getMinIndex(minHeight)
            if (this.arrIndex.length > 0) {
              pushIndex = Math.min.apply(null, this.arrIndex) // 出现高度一样的，去索引最小的
            }

            // this.$store.state.contentArr[index].top = minHeight + 20
            this.contentArr2[index].top = minHeight + 20
            this.columns[pushIndex].columnArr.push(this.contentArr2[index])
            this.loading = false
          }
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;

  .content {
    display: flex;

    #main {
      padding: 20px 0;
      margin: 0 auto;
      margin-top: 50px;
      display: flex;
      width: 100%;
      height: calc(100vh - 50px) ;
      overflow-x: hidden;
      overflow-y: scroll;
      justify-content: center; // center

      .column {
        &:nth-last-child(1):first-child .item{ // 只有一个column的时候激活这个属性
          //background-color: red;
          //width: 400px;
          //width: calc(100vh - 150px);
          //margin-left: 150px;
        }
        .note-card {
          border-radius: 4px;
          width: 14rem;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }
}

.loading{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
}
</style>
