import axios from 'axios'
import store from '@/store'

/**
 * 上传日志到服务器
 * @param payload 日志内容
 * @returns {Promise<AxiosResponse<any>>}
 */
export function add(payload) {
  // eslint-disable-next-line promise/param-names
  return new Promise((reslove, reject) => {
    axios({
      url: `${store.state.setting.serverUrl}/note-logs`,
      headers: {
        'password': store.state.setting.serverPassword
      },
      data: payload,
      method: 'POST'
    }).then(res => {
      if(res.status === 201) {
        reslove({ msg: "上传成功", code: 200, res })
      } else {
        reslove({ msg: "其他信息", code: 200, res })
      }
    }).catch(err => {
      if(err.message === 'Network Error') {
        reslove({ msg: `同步服务器:${store.state.setting.serverUrl}连接错误`, code: 500 })
      }
      if(err.response.status === 500) {
        reslove({ msg: "本条日志已经上传", code: 200 })
      }
      if(err.response.status === 401) {
        reslove({ msg: "密码错误", code: 401 })
      }
    })
  })
}

/**
 * 以当前的更新时间为起始时间，获取服务器的日志列表
 * todo 这里有点不太好，后续修改成日志没必要按时间排序，只需要堆叠就好了，最后进行日志合并的时候，可以排序进行合并，不影响数据库中的顺序
 * todo 这个时候拉取的时候，限定的规则就可以不用createAt了，直接用索引，获取最后的几条，肯定就是最新的日志信息。这个时候就可以写一个定时器，高频率的获取服务器的日志内容(因为没有进行改变的话，内容时空，比较的节省资源和流量)，如果有更新，则进行日志合并
 * @summary 拉取服务器更新的日志
 * @returns {Promise<unknown>}
 */
export function getNoteLogs() {
  // eslint-disable-next-line promise/param-names
  return new Promise((reslove, reject) => {
    // todo 在拉取更新日志的时候按日志的创建时间排了一下顺序，而且根据日志来获取要下拉的内容，感觉不太好，不好把我当前新上传的日志。
    // todo 下次改为日志就是一条一条写，拿下来的时候不按照createAt，当前写了10条日志，那么第11条，不管这个createAt是什么时候，他一定需要更新，就这么干
    axios({
      url: `${store.state.setting.serverUrl}/note-logs?_sort=createAt&_order=asc&createAt_gte=${store.state.setting.updateCreateAt + 1}`,
      headers: {
        'password': store.state.setting.serverPassword
      },
      method: 'GET'
    }).then(res => {
      console.log("服务器拉下来的日志", res.data)
      // 对比一下上传过的日志id，直接把上传过的去掉
      const logs = res.data.filter(log => !store.state.updateLogsIdList.includes(log.id))
      console.log("需要合并的日志", logs)
      reslove(logs)
    }).catch(err => {
      store.state.updateLogState = 2
      store.state.pullNoteLogFlag = false
      console.log(err)
    })
  })
}

export default {
  add
}
