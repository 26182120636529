<template>
  <div class="review-container" :class="{one: notes.length === 1}" ref="review-container">
    <div class="card" v-for="note of notes" :key="note.id">
<!--      <div class="header">-->
<!--        <span class="status">查看</span>-->
<!--      </div>-->
      <div class="content">
        <div class="text" v-html="markdown(note.content)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked'
import markedMixin from '@/mixin/markedMixin'
export default {
  name: 'RevewDialog',
  props: ['notes'],
  mixins: [markedMixin],
  methods: {
    markdown(content) {
      return marked(content)
    }
  },
  mounted () {
    // 判断进来了几个note，如果是一个，太长的话，不用居中显示
    if(this.notes.length === 1) {
      this.$nextTick(() => {
        const card = document.getElementsByClassName('card')[0]
        const reviewContainer = this.$refs['review-container']

        // console.log("happen->卡片高度", card.clientHeight, reviewContainer.clientHeight)
        if(card.clientHeight > reviewContainer.clientHeight) {
          reviewContainer.classList.remove('one')
        }
      })
    }

    // 绑定编辑框背景点击事件，目前只能这样，因为会事件冒泡，还解决不了
    this.$refs['review-container'].addEventListener('mousedown', (event) => {
      if (event.target == this.$refs['review-container']) { // 判断是否是当前绑定事件的元素元素触发的该事件
        // this.editClose()
        this.$emit('reviewClose')
        event.cancelBubble = true
      }
    })
  }
}
</script>

<style scoped lang="scss">
.review-container {
  position: fixed; // 全屏显示编辑框
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(58, 56, 56, 0.72);
  background-color: #f4f4f4;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;

  &.one {
    padding-top: 50px;
    justify-content: center;
  }
  @media screen and (max-width: 700px) {
    padding-top: 50px;
    padding-left: 10px;
  }
  .card {
    margin-bottom: 20px;
    margin-left: 10px;
    //min-width: 500px;
    @media screen and (max-width: 700px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    width: 700px; // 指定了一个宽度，如果不指定就会很贴合的

    &:only-child { // 如果只有一张卡片的话
      width: auto;
      min-width: 300px;
      max-width: 700px;

    }

    //max-width: 700px;
    border-radius: 10px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

    //height: 200px;
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    .header {
      height: 30px;
      position: absolute;
      left: 0;
      border-bottom: 1.5px solid rgba(178, 178, 178, 0.7);
      width: calc(100%);
      display: flex;
      user-select: none;
      color: #888;
      .status {
        font-weight: bold;
        //font-family: "黑体";
        margin-left: 10px;

        transform: translateY(-2px);
      }
      .tools {
        margin-left: 10px;
        button {
          background-color: transparent;
          //border: 1px red solid;
          border: 0;
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 5px;
          transform: translateY(-4px);
          &:hover {
            background-color: #dcdcdc;
          }
        }
      }
      .timeBar {
        //display: inline-block;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: translateY(-4px);

        opacity: 0;
        &:hover {
          opacity: 1;
        }

        .time {
          margin-left: 10px;
          font-size: 9px;
          color: #cecece;
        }
      }
      .close {

        margin-left: auto;
        button {
          border: 0;
          background-color: transparent;
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 5px;
          transform: translateY(-4px);

          &:hover {
            background-color: #dcdcdc;
          }
          &:active {
            //transform: scale(0.98);
          }
        }
        margin-right: 10px;
      }

    }
    .content {
      //margin-top: 35px; // 偏移出来头部的距离
      .text {
        //font-size: 14px;
        color: #333;
        font-family: "Microsoft YaHei";
        word-break: break-word;
        overflow: hidden;

        // 带有chekbox的li
        :deep .note-checkbox {
          list-style: none;
          transform: translateX(-15px);
          //padding-left: 4px;
          //display: flex;
          //align-items: center;

          input[type=checkbox] {
            //transform: translateX(-19px);
            transform: translateY(1.5px);
            -webkit-appearance: none;
            //-moz-appearance: none;
            position: relative;
            right: 0;
            bottom: 0;
            left: 0;
            height: 1.25rem;
            width: 1.25rem;
            transition: 0.2s;
            color: #fff;
            cursor: pointer;
            margin: 0.4rem 0 0.4rem;
            margin-right: 0.3rem;
            outline: 0;
            border-radius: 0.1875rem;
            vertical-align: -0.65rem;
            border: 2px solid #2f4154;

            &:after, &:before {
              content: " ";
              transition: 0.2s;
              position: absolute;
              background: #fff;
            }
            &:after {
              right: 0.5625rem;
              bottom: 0.1875rem;
              width: 0.125rem;
              height: 0;
              -webkit-transform: rotate(40deg);
              -moz-transform: rotate(40deg);
              -ms-transform: rotate(40deg);
              -o-transform: rotate(40deg);
              transform: rotate(40deg);
              transition-delay: 0.2s;
            }
            &:before {
              left: 0.125rem;
              top: 0.375rem;
              width: 0;
              height: 0.125rem;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }
            &:checked {
              background: #2f4154;
              //margin-right: 0.5rem;
              &:before {
                left: 0.0625rem;
                top: 0.625rem;
                width: 0.375rem;
                height: 0.125rem;
              }
              &:after {
                right: 0.3125rem;
                bottom: 0.0625rem;
                width: 0.125rem;
                height: 0.875rem;
              }
            }
          }
        }

        :deep img {
          width: 100%;
          border-radius: 5px;
        }

        :deep pre {
          font-size: 16px;
          padding: 0;
          margin: 0;

        }

        :deep code {
          &::-webkit-scrollbar-thumb {
            background-color: transparent;

          }
          &:hover {
            &::-webkit-scrollbar-thumb {
              background-color: rgba(0, 0, 0, 0.3);
            }
          }
        }

        :deep ol, :deep ul, :deep dl {
          //list-style: none;
          //margin: 0;
          padding-left: 1rem;
        }

        :deep .more {
          color: #cecece;
          width: 100%;
          display: inline-block;
          text-align: left;

        }

        :deep a {
          text-decoration: none;
          //color: inherit;
          //color: rgba(96, 183, 215, 0.51);
          //color: rgba(206, 206, 206, 0.6);
          color: var(--color-card-a-text);
          &:hover {
            //background-color: #efefef;
            text-decoration: underline;
          }
        }
        /* todo 随便兼容了一下，正常卡片里面不用h1和h2，因为卡片要轻，h1和h2太大了
        很多卡片结合成一篇文章的时候可以用h1或者h2来当标题 */
        :deep h1 {
          margin-top: 0;
          margin-bottom: 10px;
          color: var(--color-card-h1-text);
        }

        :deep h2 {
          margin-top: 0;
          color: var(--color-card-h2-text);
          padding-bottom: 5px;
          border-bottom: 2px solid var(--color-card-h2-border);
        }

        :deep h3 {
          color: var(--color-card-h3-text);
          padding-left: 5px;
          //border-bottom: 2px solid rgb(88 179 193 / 69%);
          border-left: 3px solid var(--color-card-h3-border);
          margin: 0
        }

        :deep blockquote {
          margin: 0;
          padding-left: 10px;
          border-left: 2px solid var(--color-card-blockquote-border);
          background: var(--color-card-blockquote-bg);
        }
      }
    }
  }
}
</style>
