<!--
渲染markdown文本内容

通过绑定传入内容，进行渲染展示
-->
<template>
  <div class="markdown-render">
    <div class="markdown-content" v-html="markdown(value)" :key="value" :style="textStyle">
    </div>
  </div>
</template>

<script>
import marked from '@/utils/markdown'

export default {
  name: 'MarkdownRender',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      textStyle: {
        // fontSize: '14px',
        // lineHeight: '1.5'
      },
    }
  },
  mounted () {

  },
  methods: {
    markdown(content) {
      return marked(content)
    },
  }
}
</script>

<style lang="scss" scoped>
.markdown-content {
  font-size: 14px;
  color: #333;
  font-family: "Microsoft YaHei",serif;
  word-break: break-word;
  //overflow: hidden;
  position: relative;

  // 思维导图外面的框
  :deep .mind-container {
    display: inline-block;
  }

  :deep .mind {
    width: 100%;
    &:hover {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.17);
    }
    // 思维导图浮动样式，代码目前没有用
    &.flow {
      z-index: 1;
      background-color: white;
      padding: 30px;
      width: 800px;
      height: 500px;
      border-radius: 5px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.17);
      position: absolute;
    }
  }

  // 带有chekbox的li
  :deep .note-checkbox {
    list-style: none;
    //transform: translateX(-15px);
    //padding-left: 4px;
    //display: flex;
    //align-items: center;

    input[type=checkbox] {
      //transform: translateX(-19px);
      //transform: translateY(1.5px);
      -webkit-appearance: none;
      //-moz-appearance: none;
      position: relative;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1.25rem;
      width: 1.25rem;
      transition: 0.2s;
      color: #fff;
      cursor: pointer;
      margin: 0.4rem 0 0.4rem;
      margin-right: 0.3rem;
      outline: 0;
      border-radius: 0.1875rem;
      vertical-align: -0.65rem;
      border: 2px solid #2f4154;

      &:after, &:before {
        content: " ";
        transition: 0.2s;
        position: absolute;
        background: #fff;
      }
      &:after {
        right: 0.5625rem;
        bottom: 0.1875rem;
        width: 0.125rem;
        height: 0;
        -webkit-transform: rotate(40deg);
        -moz-transform: rotate(40deg);
        -ms-transform: rotate(40deg);
        -o-transform: rotate(40deg);
        transform: rotate(40deg);
        transition-delay: 0.2s;
      }
      &:before {
        left: 0.125rem;
        top: 0.375rem;
        width: 0;
        height: 0.125rem;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:checked {
        background: #2f4154;
        //margin-right: 0.5rem;
        &:before {
          left: 0.0625rem;
          top: 0.625rem;
          width: 0.375rem;
          height: 0.125rem;
        }
        &:after {
          right: 0.3125rem;
          bottom: 0.0625rem;
          width: 0.125rem;
          height: 0.875rem;
        }
      }
    }
  }

  :deep .tag{
    background-color: rgb(86, 185, 186);
    color: white;
    //height: 20px;
    //margin-bottom: 2px;
    //height: 20px;
    font-size: 10px;
    padding: 0 8px;
    text-align: center;
    line-height: 20px;
    border-radius: 10px;
    //margin-right: 2px;
    cursor: pointer;
  }

  :deep img {
    width: 100%;
    border-radius: 5px;
  }
  :deep pre {
    font-size: 5px;
    padding: 0;
    margin: 0;

  }

  :deep code {
    display: block;
    overflow-x: auto;
    padding: 1em;
    // 添加一个阴影的变化
    transition: all 0.1s ease-in-out, box-shadow 2.3s cubic-bezier(0, 0, 0, 1.59); // height
    &::-webkit-scrollbar-thumb {
      background-color: transparent;

    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
      }
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.17);
    }
    // 代码浮动样式
    &.flow {
      z-index: 1;
      padding-right: 20px;
      border-radius: 5px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.17);
      position: absolute;
    }
  }

  :deep ol, :deep ul, :deep dl {
    //list-style: none;
    //margin: 0;
    padding-left: 0;
    list-style-position: inside;

    li {
      margin-bottom: 0.5rem;
    }
  }

  :deep p {
    //display: inline-block;
    //word-break: ;
  }

  :deep .more {
    color: #cecece;
    width: 100%;
    display: inline-block;
    text-align: left;

  }
  :deep del {
    background-color: rgba(88, 179, 193, 0.03);
    color: transparent;
    text-decoration: none;
    &:hover {
      background-color: transparent;
      color: var(--color-card-h2-text);
    }
  }

  :deep a {
    text-decoration: none;
    //color: inherit;
    //color: rgba(96, 183, 215, 0.51);
    //color: rgba(206, 206, 206, 0.6);
    color: var(--color-card-a-text);
    &:hover {
      //background-color: #efefef;
      text-decoration: underline;
    }
  }
  /* todo 随便兼容了一下，正常卡片里面不用h1和h2，因为卡片要轻，h1和h2太大了
  很多卡片结合成一篇文章的时候可以用h1或者h2来当标题 */
  :deep h1 {
    margin-top: 0;
    margin-bottom: 10px;
    color: var(--color-card-h1-text);
  }
  :deep p:first-child {
    margin-top: 0;
    //background-color: red;
  }

  :deep h2 {
    margin-top: 0;
    color: var(--color-card-h2-text);
    padding-bottom: 5px;
    border-bottom: 2px solid var(--color-card-h2-border);
  }

  :deep h3 {
    color: var(--color-card-h3-text);
    padding-left: 5px;
    //border-bottom: 2px solid rgb(88 179 193 / 69%);
    border-left: 3px solid var(--color-card-h3-border);
    margin: 0
  }

  :deep blockquote {
    margin: 0;
    padding-left: 10px;
    border-left: 2px solid var(--color-card-blockquote-border);
    background: var(--color-card-blockquote-bg);
  }
}
</style>
