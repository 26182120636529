<template>
  <div class="left-right-container" ref="container">
    <div class="left" ref="left">
      <slot name="left"></slot>
    </div>
    <div class="line" ref="line"></div>
    <div class="right" ref="right" v-show="rightShow">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftRightContainer',
  data() {
    return {
      rightShow: false
    }
  },
  mounted () {
    const container = this.$refs.container
    // 获取左侧的元素
    const leftChild = this.$refs.left
    // 获取中间的横线位置
    const oLine = this.$refs.line
    // 获取右侧的元素
    const rightChild = this.$refs.right

    const that = this
    oLine.onmousedown = function (ev) {
      // console.log(ev, '事件')
      const iEvent = ev || event
      const dx = iEvent.clientX
      // 当你第一次单击的时候，存储x轴的坐标。//相对于浏览器窗口
      const leftWidth = leftChild.offsetWidth
      // console.log(leftWidth, 'leftWidth')
      const rightWidth = rightChild.offsetWidth
      // console.log(rightWidth, 'rightWidth')

      const containerWidth = container.offsetWidth
      // 鼠标移动
      document.onmousemove = function (ev) {
        const iEvent = ev || event
        const diff = iEvent.clientX - dx
        // console.log(diff, '鼠标按住移动的距离')
        // 移动的距离（向左滑时为负数,右滑时为正数）
        if ((leftWidth + diff) > 100 && (rightWidth - diff) >= 0) {
          // 两个div的最小宽度均为100px
          leftChild.style.width = (leftWidth + diff) + 'px'
          rightChild.style.width = (rightWidth - diff) + 'px'
        }
        if(rightWidth - diff < 20) {
          that.rightShow = false
        } else {
          that.rightShow = true
        }
      }
    }
    // 鼠标松开后清空事件绑定
    document.onmouseup = function () {
      document.onmousedown = null
      document.onmousemove = null
    }
  }
}
</script>

<style scoped lang="scss">
.left-right-container {
  width: 100%;
  height: 100%;
  //border: 1px #dcdcdc solid;
  box-sizing: border-box;
  display: flex;

}
.left-right-container .left {
  //background-color: beige;
  width: 100%;
}
.left-right-container .line {
  cursor: e-resize;
  background-color: #dcdcdc;
  width: 3px;
}
.left-right-container .right {
  //background-color: honeydew;
  padding-left: 10px;
  flex: 1; /* 填充满左右 */
}
</style>
