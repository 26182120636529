<template>
  <div>
    <h2>登录</h2>
    <input type="text" name="username" v-model="loginData.username" placeholder="用户名"> <br>
    <input type="password" name="password" v-model="loginData.password" placeholder="密码"> <br>
    <button @click="toLogin">登录</button>
    <br>

    <hr>
    <h2>注册</h2>

    <input type="text" name="username" v-model="registerData.username" placeholder="用户名"> <br>
    <input type="text" name="password" v-model="registerData.password" placeholder="密码"> <br>
    <input type="email" name="email" v-model="registerData.email" placeholder="邮箱"> <br>
    <button @click="toRegister">注册</button>
    <hr>
    {{message}}
<!--    {{token}}-->
  </div>
</template>

<script>
import { getInfo, login, register } from '@/api/user'
import { getToken, setToken, setUser } from '@/utils/auth'

export default {
  name: 'index',
  data() {
    return {
      loginData: {
        username: '',
        password: ''
      },
      registerData: {
        username: '',
        password: '',
        email: ''
      },
      token: '',
      message: ''
    }
  },
  mounted () {
    const token = getToken()
    this.token = token
    getInfo(token).then(res => {
      console.log(res)
      if(res.code === 200) {
        // this.$store.state.user.username = res.data.user_info.username
        setUser(res.data.user_info)
      } else {
        alert("登录失效，请重新登录")
      }
    })
  },
  methods: {
    toRegister() {
      register(this.registerData).then(res => {
        console.log(res)
        if(res.code === 200) {
          this.message = '注册成功，请点击登录'
          this.loginData.username = this.registerData.username
          this.loginData.password = this.registerData.password
        } else {
          this.message = res.message
        }
      })
    },
    toLogin() {
      login(this.loginData).then(res => {
        if (res.code === 200) {
          const token = 'Bearer ' + res.data.token
          this.$store.state.user.token = token
          this.$store.state.user.username = res.data.user_info.username
          setToken(token)
          setUser(res.data.user_info)
        } else {
          this.message = res.message
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
