const TokenKey = 'note-Token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getUser() {
  return JSON.parse(localStorage.getItem('note-user-info') || '{}')
}

export function setUser(user) {
  return localStorage.setItem('note-user-info', JSON.stringify(user))
}

export function removeUser() {
  return localStorage.removeItem('note-user-info')
}

