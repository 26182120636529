<template>
<div class="v2">
  基础卡片组件
  <Card>
    卡片中的内容
  </Card>
  单独一个笔记
  <NoteCard class="note-card" :note="note" @click="noteClick" @dblclick="noteDblClick"></NoteCard>
  笔记列表
  <div class="note-card-list">
    <NoteCard class="note-card" v-for="note in noteList" :note="note" :key="note.id" @click="noteClick" @dblclick="noteDblClick" @save="saveNote"></NoteCard>
  </div>
  动态插槽
  <SlotTest>
    <template v-for="note in noteList" v-slot:[note.id]>
      <NoteCard class="note-card" :note="note" :key="note.id" @click="noteClick" @dblclick="noteDblClick" @save="saveNote"></NoteCard>
    </template>
  </SlotTest>

  通过配置修改笔记样式
  <textarea v-model.lazy="noteStyle"></textarea>
  <button @click="addNoteStyle">修改</button>
</div>
</template>

<script>
import Card from '@/components/Card'
import NoteCard from '@/components/NoteCard'
import SlotTest from '@/components/Layout/SlotTest'
export default {
  name: 'Main',
  components: { NoteCard, SlotTest, Card },
  data() {
    return {
      noteStyle: `.markdown-content {
  font-size: 20px !important;
  color: blue !important;
}

.markdown-content > h1 {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  color: red !important;
}

.markdown-content > blockquote {
  margin: 0 !important;
  padding-left: 10px !important;
  border-left: 2px solid #42b0c1 !important;
  background: #b8eaf5 !important;
}`,
      note: {
        content: "# 这是一个笔记\n\n> 这是一个引用\n\n你好",
        background: '#ffffff', // 背景颜色
        color: '#333333', // 字体颜色
        top: '',
        tags: {}, // { '网址': { background: '#56b9ba', color: '#fff', smart: false } }, // 智能标签，智能标签每次更新文本的时候会自动删除，然后根据文本内容来自动生成标签
        state: 0, // 0-正常 1-删除 2-归档
        secret: false, // 是否隐藏
        type: '', // 笔记类型
        height: 100,
      },
      noteList: [
        { "id": "_ntu98RFU5kl8IhVIsVln", "content": "真是很好看的视频 来自：https://www.bilibili.com/video/BV1Lg411r74j?spm_id_from=333.1007.tianma.1-1-1.click&vd_source=68a9f316a94ae92d0df40de2afcb1258", "tags": { "网址": { "background": "#56b9ba", "color": "#fff", "smart": true }, "哔哩哔哩": { "background": "#fb7299", "color": "#ffffff", "smart": true } }, "background": "#ffffff", "color": "#333333", "top": 333, "type": "", "height": 226, "state": 0, "secret": false, "createAt": 1661702270776, "updateAt": 1661702270776 },
        { "id": "_Ma-XTO2Q0FlJBKIYZoVG", "content": "啦啦啦2", "tags": {}, "background": "#ffffff", "color": "#333333", "top": 0, "type": "", "height": 100, "state": 1, "secret": false, "createAt": 1661698244694, "updateAt": 1661698244694 },
        { "content": "### 可见一斑\n\n比喻见到事物的一小部分也能推知事物的整体。与“以小见大”相似。\n\n> 引用", "background": "#ffffff", "color": "#333333", "top": 668, "tags": {}, "state": 0, "type": "", "height": 213, "id": "pj7guKERjjkvdUIbfmI2A", "createAt": 1657700699005, "updateAt": 1657702740902 },
        { "content": `卷积 (Convolution) 是一种数学运算，它用于在两个函数之间进行运算。在卷积神经网络中，卷积操作通常用于对图像进行处理。\n\n卷积操作的基本思想是，使用一个小的矩阵，称为卷积核（或滤波器），在图像上滑动并与图像的每个部分进行运算。这个运算的结果就是新的图像，称为特征图。\n\n每个卷积核都会学习一个特定的特征，如线条、边缘或斑点等。通过使用多个卷积核，卷积神经网络可以学习出图像的多层次特征。\n\n简单来说，卷积是一种对图像进行特征提取的方法。`, "background": "#ffffff", "color": "#333333", "top": 668, "tags": {}, "state": 0, "type": "", "height": 213, "id": "pj7guKERjjkvdUIbfmI2A-1", "createAt": 1657700699005, "updateAt": 1657702740902 },
      ]
    }
  },
  methods: {
    saveNote(note) {
      this.note = note
    },
    addNoteStyle() {
      const style = document.createElement('style')
      style.type = 'text/css'
      style.innerText = this.noteStyle
      console.log("修改的样式:", this.noteStyle)
      document.getElementsByTagName('head').item(0).appendChild(style)
    },
    noteClick(event) {
      console.log("单击", event)
    },
    noteDblClick(event) {
      console.log("双击", event)
    }
  }
}
</script>

<style lang="scss" scoped>
.v2 {
  background-color: #f4f4f4;
  box-sizing: border-box;
  padding: 10px;
  //height: 100vh;
}
.note-card {
  //width: 14rem;
  //margin: 10px 5px;
  //padding: 10px 5px;
  margin-bottom: 10px;
}
</style>
